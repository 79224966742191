@import '../../Scss/main.scss';
@import '../../Css/theme.css';

.swap-full .ticket-box {
    max-width: 600px;
    margin: 0 auto;
    border-radius: 10px;
    background: rgba(246, 250, 255, 1) !important;
    border: 1px solid #1976d2;
}
body.dark-theme .swap-full .ticket-box{
    background: #121318 !important;
    border: none;
}
.balance-input {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: center;
    border: 1px solid #dedede;
    border-radius: 6px;
    background-color: var(--bg-input);
}
.text-area fieldset.MuiOutlinedInput-notchedOutline {
    border: none !important;
}
.spend-input {
    margin-bottom: 25px;
}
.balance {
    padding-bottom: 15px;
}
.pecentage-box {
    margin-bottom: 25px;
   background: rgba(150, 198, 255, 1);
    padding: 20px;
    border-radius: 6px;
    margin: 50px 0;
}
body.dark-theme .pecentage-box{
    background: #000;
}
.convert-btn {
    text-align: center;
}
.spend-input .balance:nth-child(2){
    text-align: right;
}
.spend-input .balance label{
    color: var(--clr-font);
}
.text-area input, .pecentage-value span{
    color: var(--clr-font);
}
.select-drop .MuiInputBase-root {
    color: #000;
    height: 46px;
}
body.dark-theme .balance-input{
    border: 1px solid #312d2d
}
.swap-full h1 {
    text-align: center;
    margin: 30px 0;
}
.select-drop .MuiFormControl-root{
    background: linear-gradient(179deg, rgba(147, 211, 255, 1) -0.11%, rgba(215, 239, 255, 1) 97.52%) !important;
    border: none;
    border-radius: 6px;
}
.convert-btn button{
    background: $btn-primary;
    width: 100%;
    padding:$pad-10px;
}