@import '../../Scss/main.scss';



.staking p.cmn-text {
    color: #848E9C !important;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}
.stackingbannerright .card {
    border-radius: 8px;
    display: flex;
    gap: 70px;
    flex-direction: row;
    padding: 50px;
}
.stackingbannerright {
    width: 50%;
}
.stacking-card {
    position: relative;
}
.stacking-card .toggle-password {
    position: absolute;
    right: 25px;
    top: 16px;
    font-size: 16px;
    z-index: 1;
    color: #848E9C;
}
.stackingbannerright .card {
    border-radius: 8px;
    display: flex;
    gap: $gap-70;
    flex-direction: $flex-row;
    padding: 50px;
    background: var(--clr-bcg);
}
.stackingbannerright .card .xchange-rate h4 {
    color: var(--clr-font) !important;
    font-size: $font-14;
    font-weight: $weight-400;
    line-height: 20px;
}
.stackingbannerright .card .xchange-rate h5 {
    font-size: 1.25rem;
    color: var(--clr-font);
}

.xchange-rate h3 {
    color: #0077cc;
    font-size: $font-20;
    line-height: 28px;
    font-style: normal;
    font-weight: $weight-500;
    margin-top: 24px;
}
.xchange-rate a{
    text-decoration: none;
}
.staking.display-1{
    align-items: flex-start;
}
.value-hide-show .MuiFormControl-root fieldset{
    border: 0 !important;
}
.passwordIcon{
    position: absolute !important;
    right: 34px;
    top: 12px;
}
.value-hide-show input {
    padding-left: 0 !important;
    color: var(--clr-font);
}
.stacking-card svg.MuiSvgIcon-root{
 fill: var(--clr-font);
}
.rules-staking .MuiTabs-scroller.MuiTabs-hideScrollbar{
    display: flex;
    margin-top: 25px;
    background: var(--clr-bcg);
    // width: 180px;
    padding: 6px;
    border-radius: 9px;
    justify-content: space-around;
    align-items: center;
}
.rules-staking button.MuiTab-root{
    padding: 5px 12px;
    width: 140px;
    justify-content: center !important;
}
.rules-staking button.MuiTab-textColorPrimary.Mui-selected {
    background: #0077cc !important;
    border-radius: 6px !important;
    color: #fff !important;
}
.rules-staking span.MuiTabs-indicator{
    display: none;
}
.card-stacking .contain-width.sitecontainer.stacking{
    background: transparent !important;
    padding-top: 50px;
    padding-bottom: 50px;
}
.card-stacking .eth-card-total {
    gap: 35px;
    margin-bottom: 0;
    display: flex;
}
.card.border-style-cls {
    background: #0077cc !important;
    border: 1px solid rgba(0, 0, 0, .125);
    border-radius: .25rem;
    padding: 25px;
}
.stacking .card.border-style-cls h4 {
    font-size: 20px !important;
    margin-bottom: 30px;
    color: #fff;
}
.card.border-style-cls h6 {
    color: #fff !important;
    font-size: $font-16;
    margin-bottom: 20px;
}
.stacking .card.border-style-cls .card-btn a {
    display: inline-block;
}

.stacking .card-btn a {
    border-radius: 8.669px;
    border: 0.867px solid #FFF;
    color: #0077cc !important;
    background: #fff !important;
    padding: 10px;
    text-align: center;
    font-size: $font-16;
    font-style: normal;
    font-weight: $weight-500;
    line-height: 20px;
    text-decoration: none;
}
.staking-datatable {
    background: #fff !important;
    padding: 20px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 5px 15px;
}
.staking-body{
    margin: 20px 0;
}
.staking table thead tr th {
    white-space: pre;
    color: var(--clr-font);
}


// subscribe
.saving-page .data-cnt{
    display: flex !important;
    align-items: center;
    margin-bottom: 20px;
    border-bottom: 1px solid #000;
    padding-bottom: 15px;
}
.cryptosaving .saving-page .duration {
    font-weight: $weight-400;
    font-size: $font-14;
    margin-bottom: 10px;
    color: var(--clr-font);
}
.cryptosaving .ratio-btn label {
    padding: 10px 20px;
    display: inline-block;
    border: 1px solid grey;
    cursor: pointer;
    color: #EAECEF;
    text-align: center;
    font-size: $font-16;
    font-style: normal;
    font-weight: $weight-400;
    border-radius: 4.679px;
    background: #2B3139;
    width: auto !important;
}
.cryptosaving .ratio-btn input[type="radio"] {
    display: none;
}
.cryptosaving .saving-page .max-amt {
    margin: 10px 0px 4px;
    display: inline-flex;
    position: relative;
    -webkit-box-align: center;
    align-items: center;
    line-height: 1.6;
    border: 1px solid rgb(234, 236, 239);
    border-radius: 4px;
    height: 48px;
    width: 100%;
    // border: 1px solid #000;
}
.cryptosaving .saving-page .input-box {
    width: 100%;
    height: 100%;
    padding: 0;
    outline: none;
    border: none;
    background-color: inherit;
    opacity: 1;
    // padding-left: 10px;
}
.cryptosaving .saving-page .max-amt .MuiInputBase-root.MuiOutlinedInput-root{
    height: 48px;
}
.cryptosaving .saving-page .MuiInputBase-root.MuiOutlinedInput-root{
width: 100%;
}
.cryptosaving .max-btn {
    display: $flex;
    align-items: $align-c;
}
.cryptosaving .profile-change {
    margin-top: 10px;
    color: var(--clr-font);
}
.cryptosaving .saving-page .avaliable-btn h4, .saving-page .minmum h4 {
    color: var(--clr-font);
    font-size: 14px !important;
    margin-bottom: 0;
    margin: 0;
    font-weight: $weight-500;
}
.cryptosaving .saving-page .avaliable-btn span, .saving-page .minmum h4 span {
    color: #707A8A;
    font-size: 14px;
    font-weight: 400;
}
.cryptosaving .saving-page .enough {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 4px;
    background: rgba(254, 246, 216, 0.30);
    box-shadow: 0px 3px 6px 0px rgba(24, 26, 32, 0.08), 0px 7px 14px 0px rgba(71, 77, 87, 0.08), 0px 0px 1px 0px rgba(24, 26, 32, 0.10);
    padding: 15px;
    margin-top: 17px;
}
.cryptosaving .saving-page .enough .info {
    display: flex;
    align-items: center;
    gap: 10px;
}
.cryptosaving .saving-page .enough .info h4 {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    margin: 0;
}
.info a {
    color: #000;
    text-decoration: none;
}
.cryptosaving .saving-page .enough svg.MuiSvgIcon-root{
    color: #0077cc;
}
.cryptosaving .saving-page .minmum {
    margin-top: 40px;
}
.cryptosaving .saving-page .cnfrm-btn button {
    border-radius: 4px;
    background: var(--urumi, linear-gradient(276deg, #0077cc -8.51%, #5982b5 101.62%));
    padding: 10px 60px;
    color: #FFF !important;
    text-align: center;
    font-size: $font-16 !important;
    font-style: normal;
    font-weight: $weight-400 !important;
    line-height: 20px;
    letter-spacing: 1px;
    border: none;
    text-transform: $cap;
}
.container.sitecontainer.cryptosaving {
    max-width: 60%;
    margin: auto;
}
.cryptosaving .saving-page .left-txt {
    margin-top: 50px;
}
.summary-detail h4 {
    font-size: $font-25;
    margin-bottom: .5rem;
}
.locked-est {
    display: $flex;
    justify-content: $justi-sb;
    align-items: $align-c;
    color: var(--clr-font);
}
.est-update p {
    margin: 10px 0;
    color: var(--clr-font);
}
.apr-rules ul {
    padding-left: 20px;
}
.cryptosaving .saving-page .cnfrm-btn {
    margin-top: 20px;
    text-align: center;
    margin-bottom: 50px;
}
.payment-left-main.ticket-box ul li{
    color: var(--clr-font);
}

