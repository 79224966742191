@import '../../Scss/main.scss';
@import '../../Css/theme.css';

.ticket-box.market-tab button, .market-current-history button {
    font-size: 16px;
    font-weight: 500;
    position: relative;
    color: var(--grey);
}
.ticket-box.market-tab button.Mui-selected{
    color: var(--clr-font) !important;
}
.market-current-history button.Mui-selected{
    color: #fe7701 !important;
    border-bottom: 2px solid #fe7701 ;

}
.ticket-box.market-tab button:hover, .market-current-history button:hover{
    color: var(--clr-font) ;
}
.token-rate{
    color: var(--clr-font);
}
// .ticket-box.market-tab button:hover::after, .market-current-history button:hover::after{
//    position: absolute;
//    content: "";
//     background: #1976d2;
//     width: 80%;
//     height: 2px;
//     position: absolute;
//     bottom: 0;
//     left: 50%;
//     transform: translate(-50%);
//     transition: 0.2s;
// }
.token-logo {
    font-size: 15px;
}

.token-name {
    display: flex;
    align-items: center;
    gap: 10px;
}
.token-card { 
    position: relative;
    padding: 13px 22px;
    // box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 10px;
    background: var(--clr-bk);
    align-items: center;
    color: var(--clr-font);
}
.gainer-history {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
}

.token-logo img{
    width: 30px;
} 
@keyframes fadeIn {
    100% {
        opacity: 1;
        transform: none;
    }
}

.low-bg span{
    background: #ed4545;
    color: #fff;
    padding: 9px 10px;
    border-radius: 6px;
}
.high-bg span{
    background: #12b298;
    color: #fff;
    padding: 9px 10px;
    border-radius: 6px;
}
.trade-btn a{
    text-decoration: none;
    color: var(--clr-font);
    border: 2px solid #e6e8ec;
    padding: 10px 22px;
    border-radius: 6px;
}

 



.market_overview .box{
    // background: var(--clr-bk);
    border: 1px solid var(--bdr-colr);
    padding: 20px;
    border-radius: 8px;
}
.market_overview .box:hover{
    transform: translateY(-12px);
    transition: all 0.3s ease;
}
.market_overview .abv_flx{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--bdr-colr);
    padding-bottom: 20px;
    margin-bottom: 20px;
}
.market_overview .abv_flx .flx{
    display: flex;
    align-items: center;
    gap: 10px;
}

.market_overview .abv_flx h5{
    font-weight: 600;
    font-size: 16px;
    line-height: 125%; 
    margin: 0;
    color: var(--clr-font);
}

.market_overview .abv_flx a{
    font-weight: 500;
    font-size: 14px;
    line-height: 143%;
    text-align: center;
    color: rgba(243, 243, 243, 0.4);
}

.market_overview .box ul{
    padding: 0;
    margin: 0;
}
.market_overview .box li{
   display: flex;
   align-items: center;
   color: var(--clr-font);
   margin-bottom: 20px;
}
.market_overview .box li>div:not(:first-child){
   justify-content: flex-end;
}
.market_overview .box li>div{
    width: 33.3%;
    display: flex;
    align-items: center;
}
.market_overview .box .num{
    color: var(--grey);
}
.market_overview .box .coin{
    border-radius: 50%;
    width: 20px;
    height: 20px;
    margin: 0 10px 0 15px;
}
.market_overview .box .green{
    color: var(--green);
    margin-right: 4px;
}
.market_overview .box .red{
    color: var(--red);
    margin-right: 4px;
}


.token-rate h5{
    margin: 0;
    margin-bottom: 4px;
    font-weight: 600;
    font-size: 22px;
    line-height: 31px; 
}
.token-rate .green{
    margin: 0;
    color: var(--green);
}
.token-rate .red{
    margin: 0;
    color: var(--red);
}
.token-rate input[type="radio"]{
  background-color: transparent;
  border: 1px solid var(--grey) !important;
  box-shadow: none !important;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  appearance: none;
  margin: 0;
}
.token-rate input[type="radio"]:checked{
    background:url("../../Img/tic_white.svg") no-repeat center var(--grey1);
    background-size: 15px;
}
.token-logo:nth-child(2){
    padding-top: 17px;
}


 .ticket-box{
    border-radius: 15px;
    background: rgba(243, 243, 243, 0.02) !important;
}
.market-current-history .MuiButtonBase-root{
    padding: 0;
    min-width: unset;
    text-transform: capitalize;
}
.market-current-history .MuiTabs-flexContainer{
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
}
.market-current-history .css-1aquho2-MuiTabs-indicator{
    background-color: var(--clr-font);
    height: 3px;
    border-radius: 20px;
}
.market-current-history .MuiTabs-root {
    padding: 0;
}
.market-current-history .tab_bdr{
    // border-bottom: 1px solid var(--bdr-colr1);
    margin-bottom: 30px;
    flex-wrap: wrap;
    gap: 10px;
   padding-left: 15px;
}
.market-current-history .h_2{
  padding-left: 15px;
}
.tab-wrap{
    gap: 15px;
    flex-wrap: wrap;
}
.market-current-history .ticket-box{
    height: auto !important;
    padding: 0;
    background: transparent !important;
}
.market-current-history .tab-content>div{
   padding-left: 0;
   padding-right: 0;
}
.market-current-history_chg button.MuiButtonBase-root {
    padding: 9px 25px;
    border-radius: 111px;
    color: var(--grey) !important;
    background: var(--clr-bk);
    border: 1px solid var(--bdr-colr);
    min-height: unset;
}
body.light-theme .market-current-history_chg button.MuiButtonBase-root:not(.Mui-selected) {
    background: transparent;
    border: 0;
}
.market-current-history_chg button.Mui-selected{
    color: #000 !important;
    background:#fff !important;
}
.market-current-history_chg .css-1aquho2-MuiTabs-indicator{
    background-color: transparent;
}
.sm_title{
    color: var(--clr-font);
    font-weight: 600;
    margin-bottom: 30px;
}
.sub_head{
    color: var(--clr-font);
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 30px;
}
.sub_text{
    color: var(--grey);
    font-weight: 400;
font-size: 14px;
margin-bottom: 30px;
}

.market-table .coin-gap p{
    font-weight: 500;
    margin: 0;
    color: var(--clr-font);
    font-size: 16px;
}
.market-table .coin-gap span{
    font-weight: 400;
    font-size: 12px;
    color: var(--grey);
}

    .market-table th {
        font-size: 14.2px;
        font-weight: 600;
        background: rgba(243, 243, 243, 0.02);
        border-bottom: 0 !important;
        white-space: nowrap;
        color: var(--clr-font);
    }
    .market-table th:first-child{
        border-radius: 12px 0 0 12px;
    }
    .market-table th:last-child{
        border-radius:0 12px  12px 0;
    }
    .market-table td.MuiTableCell-root {
        color: var(--clr-font);
        border-bottom: 1px solid var(--bdr-colr1);
        font-size: 16px;
    }
    .market-table .coin-img img{
        min-width: 25px;
        height: 25px;
    }
    .market-table .actions a{
       display: inline-block;
       color: var(--green) !important;
       background: transparent !important;
       text-decoration: none !important;
       margin: 0 4px;
    }
    .market-table td.red{
        color: var(--red) !important;
    } 
    .market-table td.green{
        color: var(--green) !important;
    } 












.input_grp{
    border: 1px solid var(--grey);
    display: flex;
    border-radius: 8px;
}
.input_grp .input_txt{
    padding: 10px;
    padding-left: 13px;
    padding-right: 13px;
}
.input_grp .input_txt.left{
    padding-right: 10px;
}
.input_grp input{
    background: transparent !important;
    border: 0 !important;
    box-shadow: none !important;
    outline: 0 !important;
    color: var(--clr-font);
    padding: 5px;
}
.input_grp input::placeholder{
    color: var(--grey);
    font-weight: 500;
    font-size: 14px;
}

.check_box {
    display: flex;
    align-items: center;
    gap: 5px;
}
.check_box .chk{
    position: relative;
}
.check_box input{
    opacity: 0;
    width: 22px;
    height: 22px;
    z-index: 1;
    position: relative;
}
.check_box .round{
    border: 1px solid var(--grey);
    min-width: 20px;
    height: 20px;
    border-radius: 50%;
    display: inline-block;
    margin-left: -25px;
}
.check_box input:checked + .round{
    background: url("../../Img/tic_white.svg") #000 no-repeat center;
    background-size: 15px;
}
.check_box .label_txt{
    font-size: 14px;
    color: var(--grey);
}

.invert{
    filter: var(--invert);
}
.cursor-pointer{
    cursor: pointer;
}

.view-all .MuiPagination-ul li button {
    color: rgba(20, 221, 182, 1);
    ;
  }
  
  .view-all .MuiPagination-ul li button.Mui-selected {
    border: 1px solid;
  }
  
  .view-all .MuiPagination-ul {
    justify-content: center;
  }
  .view-all .MuiPaginationItem-root{
    color: rgb(20, 221, 182);
  }