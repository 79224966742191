@import '../../Scss/main.scss';


.id-popup {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    background: var(--clr-card);
    padding: 25px;
    color: var(--clr-font);
    border-radius: 8px;
}

.id-popup:focus-visible {
    outline: none !important;
}

.verify-know a {
    background: rgba(255, 112, 40, 1);
    color: #fff;
    padding: 10px 22px;
    border-radius: 40px;
    display: inline-block;
    width: 85%;
}

.verify-know {
    margin-top: 30px;
}

.contain-width {
    padding: 0 30px;
    max-width: 1506px !important;
    margin: 0 auto;
}

.id-kyc.display-1 {
    gap: 15px;
    margin-top: 15px;
    overflow: scroll;
}

.id-verify {
    padding: 15px 43px 13px 23px !important;
    border-radius: 40px;
    height: 44px;
    color: var(--clr-font) !important;
    flex-direction: row !important;
    min-height: 52px !important;
    font-weight: 600 !important;
    text-transform: capitalize !important;
    font-size: 16px !important;
}

.id-verify.Mui-selected {
    background: rgba(188, 95, 1, 0.08);
    border-radius: 40px;
    color: rgba(255, 112, 40, 1) !important;
}

.id-verify.Mui-selected svg path {
    fill: rgba(255, 112, 40, 1) !important;
    ;
}

.id-verify.active {
    background: rgba(188, 95, 1, 0.08);

}

.id-verify.active svg path {
    fill: rgba(255, 112, 40, 1);
}

.verify-know.text-center.time-zone-btn {
    margin-top: 20px;
    padding: 0 25px 25px;
}

.time-zone-close {
    padding: 10px 10px 0 0;
}

.time-zone-pop .MuiPaper-root.MuiPaper-elevation {
    background: var(--clr-card);
    color: var(--clr-font);
}

select.select-outline .MuiNativeSelect-select {
    color: var(--clr-font);
}

.profile-change label {
    text-transform: capitalize !important;
    display: flex;
    flex-direction: row-reverse;
    gap: 21px;
}

.profile-avatarnew {
    align-items: flex-start;
    flex-wrap: wrap;
}

.profile-avatarnew h5 {
    margin: 0 0 2px;
    font-size: 18px;
}

.profile-avatarnew span {
    color: rgba(173, 179, 191, 1);
    font-size: 12px;
}

.profile-change {
    background: var(--bg-dark-card);
    padding: 15px 20px;
    border-radius: 10px;
    margin-bottom: 25px;
    flex-wrap: wrap;
    gap: 10px;

}

.email-box.text-center {
    background: var(--card-bg);
    padding: 17px;
    width: 80px;
    border-radius: 8px;
}

.email-box p {
    padding-top: 7px;
}

.coin-value {
    margin: 10px;
}

.coin-value .MuiNativeSelect-select {
    color: var(--clr-font);
    padding: 5px 18px;
}

.coin-value .MuiInputBase-root:before {
    border-bottom: 0;
}

.coin-value .MuiInputBase-root:hover:before {
    border-bottom: 0 !important;
}

.coin-value select option {
    background: var(--card-bg) !important;
}

.MuiFormLabel-root,
.MuiOutlinedInput-notchedOutline {
    color: var(--clr-font) !important;
    border-color: var(--clr-font) !important;
}

.assets-overview .assets-order a {
    color: var(--clr-font);
    font-size: 14px;
}

.assets-order.display-1 {
    justify-content: flex-end;
    margin-bottom: 20px;
    flex-wrap: wrap;
}
.security-tab .MuiTabs-flexContainer{
    display: flex;
    width: 100%;
    overflow: scroll;
}
.security-tab.display-2 {
    flex-wrap: wrap;
    gap: 15px;
    margin-top: 25px;
}

.assets-order.display-1.deposit-btn a {
    border: 1px solid var(--clr-font);
    padding: 8px 22px;
    border-radius: 40px;
    font-weight: 600;
    color: var(--clr-font);
}

.assets-order.display-1.deposit-btn a.active {
    background: rgba(255, 112, 40, 1);
    border-color: rgba(255, 112, 40, 1);
    color: #000;
}

.assets-order.display-1.deposit-btn a:hover {
    border-color: rgba(255, 112, 40, 1);
    background: rgba(255, 112, 40, 1);
}

body.light-theme .profile-change.display-2.edit-profile-img {
    border: 1px solid rgba(255, 112, 40, 1);
}

.time-zone p {
    margin: 4px;
}

.dashboard-full {
    margin-top: 50px;
    color: var(--clr-font);
}

.Profile span.MuiTabs-indicator {
    background: none;
}

p.two-fa {
    max-width: 529px;
    font-size: 15px;
    line-height: 1.4;
}

body.dark-theme p.two-fa,
body.dark-theme .email-box-auth p {
    color: rgba(243, 243, 243, 0.4);
}

.email-box-auth.display-1 {
    gap: 50;
}

.email-box-auth h5 {
    margin-bottom: 0;
    font-size: 17px;
}

.email-box-auth button {
    color: var(--clr-font);
    border: 1px solid;
    border-radius: 40px;
    padding: 10px 29px;
    height: 35px;
}

.email-box-auth p {
    margin-top: 9px;
    font-size: 14px;
}

.email-box-auth {
    margin-bottom: 25px;
    flex-wrap: wrap;
    gap: 10px;
}

.code-box fieldset.MuiOutlinedInput-notchedOutline {
    border-color: var(--clr-font);
    height: 47px;

}

.code-box .MuiFormControl-root {
    margin-top: 15px;
}

.code-box input {
    color: var(--clr-font);
    padding: 10px 15px;
}

.code-box {
    margin-top: 20px;
}

.code-box .MuiInputBase-root:hover {
    border: 1px solid #fff;
}

.submit-btn button {
    background: rgba(255, 112, 40, 1);
    color: #fff;
    height: 40px;
    padding: 10px 32px;
}

.submit-btn button:hover {
    background: rgba(255, 112, 40, 1);
}

.qr-code img {
    width: 150px;
}

.qr-code {
    margin-top: 21px;
    text-align: center;
}

.profile-avatarnew img {
    border-radius: 50px;
    width: 50px;
    height: 50px;
}

.verification.display-1 {
    flex-wrap: wrap;
}

.kyc-img img {
    width: 150px;
}

.dashboard-full.kyc-verify {
    border: 1px solid rgba(243, 243, 243, 0.08);
    padding: 25px;
    border-radius: 8px;
    flex-wrap: wrap;
    gap: 25px;
}

.dashboard-full.kyc-verify h2 {
    margin: 0;
}

.dashboard-full.kyc-verify p {
    font-size: 14px;
}

body.dark-theme .dashboard-full.kyc-verify p {
    color: rgba(243, 243, 243, 0.4);
}

.kyc-verify button,
.kyc-verify button:hover {
    color: #fff;
    display: flex;
    gap: 10px;
    background: rgba(254, 119, 1, 1);
    border-radius: 40px;
    padding: 11px 22px;
    height: 40px;
    margin-top: 25px;
}

// .kyc-verify button:hover svg{
//     animation: bounce 1s infinite
// }

// @keyframes bounce {
//     0%, 20%, 50%, 80%, 100% {
//         transform: rotate(360deg) translateY(0);
//     }
//     40% {
//         transform: rotate(360deg) translateY(-10px);
//     }
//     60% {
//         transform: rotate(360deg) translateY(-5px);
//     }
// }

.tab-box .MuiBox-root {
    padding: 0;
}

.encrypt-code {
    margin-top: 25px;
}

.coin-value svg {
    color: var(--clr-font) !important;
}

.code-box button {
    padding: 0;
    display: block;
    margin-top: 22px;
    color: var(--clr-font);
}

.reset-modal h1,
.reset-modal .email-name,
.reset-modal .email-fill input,
.reset-modal .email-fill svg {
    color: var(--clr-font);
}

.enable-disable button.green-border {
    border-color: green;
    color: green;
}

.enable-disable button.green-border:hover {
    background: green;
    color: #fff;
}

.enable-disable button.red-border {
    border-color: red;
    color: red;
}

.enable-disable button.red-border:hover {
    background: red;
    color: #fff;
}

.profile-avatarnew button,
.profile-avatarnew button span {
    padding-left: 0;
}

.imp-note p {
    font-size: $font-15;
}

.imp-note svg {
    width: 21px;
}

.imp-note {
    margin-top: 15px;
    background: var(--bdr-btm);
    padding: 0 10px;
    border-radius: 8px;
}

.pencil-icon {
    position: relative !important;
    top: 60px;
    left: 15px;
    z-index: 1;
}

.pencil-icon svg {
    fill: var(--clr-font);
}

.setting-popup {
    height: auto !important;
}
.pad_0 > div {
    padding: 0;
}