@import '../../Scss/main.scss';


.payment-left-main,
.payment-right-main {
    // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: $radius-12px;
    padding: $px-10;
    margin: $mar-10px;
}
.username-main {
    padding: $pad-10px;
    text-align: $center;

}
.username-main div {
    margin: $px-10;
}
.vertiacal-tab-payment button.MuiTab-root {
    // min-width: 50% !important;
    justify-content: flex-start !important;
    color: var(--clr-font);
}
h1.cmn-text  {
    font-weight: $weight-600 !important;
    // text-transform:$upper;
    color: var(--clr-font);
    font-size: 30px;
    margin: 20px 10px;
}
.vertiacal-tab-payment .MuiTabs-flexContainer {
    align-items: $justi-l;
    display: $flex;
    flex-direction: $flex-row;
    overflow: scroll;
}
.payment .MuiTabs-indicator{
    display: $d-none;
}
.payment .add-ac{
    color: #fff;
    background: $btn-primary;
    margin-right: 10px;
    padding: $pad-10px;
    border-color: $btn-primary !important;
    text-decoration: $d-none;
    font-size: $font-16;
    border-radius: .25rem;
}
.vertiacal-tab-payment button.MuiTab-textColorPrimary.Mui-selected {
    background-color: cadetblue;
    color: #fff;
    font-weight:$weight-600;

    border-bottom: 2px solid rgba(255, 112, 40, 1) !important;
    color: rgba(255, 112, 40, 1) !important;
    border: 0;
    background: transparent !important;
}
.container.sitecontainer {
    padding: 40px;
    background: var(--bg-contain);
    border-radius: 10px;
}
.payment th.MuiTableCell-root.MuiTableCell-head{
    color: var(--clr-font);
    font-weight: $weight-600;
    border-bottom: 1px solid var(--bdr-btm);
    white-space: nowrap;
}
.payment th.MuiTableCell-root.MuiTableCell-body, .payment td.MuiTableCell-root.MuiTableCell-body{
    color: var(--clr-font);
    border-bottom: 1px solid var(--bdr-btm);
    white-space: nowrap;
}
.no-data{
    text-align: center !important;
}
.badge-danger {
    background-color: #fc4c4c !important;
    padding: 6px 7px !important;
    cursor: pointer;
    color: #fff !important;
}
.dark-mode-img, .dark-theme .light-mode-img {
    display: $d-none;
}
.dark-theme .dark-mode-img{
    display: block;
}

.account-head {
    display: flex;
    align-items: center;
    gap: 14px;
    margin-top: 30px;
    justify-content: $center;
}
.account-head h2 {
    color: var(--clr-font);
}
.profile-change p {
    margin: 0 10px;
    color: var(--clr-font);
}
.new-account .payment-left-main.ticket-box{

max-width: 800px;
margin: auto;
padding: 50px;
}
.new-account .choose-file input {
    font-size: 17.5px;
    font-weight: 500;
    // padding: 0 !important;
}
.profile-change.choose-file label{
    display: none;
}
.new-account .profile-change .MuiFormControl-root {
    margin: 10px;
    width: 100% !important;
}
.profile-change.submit .MuiFormControl-root {
    width: fit-content !important;
}
.profile-change.submit input {
    width: fit-content;
    padding: 8px 28px;
    border: 0px;
    color: #fff;
    font-size: 14px;
    border-radius: 4px;
    text-transform: uppercase;
    font-weight: 700;
    background: $btn-primary !important;
    transition: none !important;
}
.profile-change.submit{
    text-align: left !important;
}
.profile-change.choose-file label.MuiInputLabel-shrink {
    display: block;
}
.profile-change label{
text-transform: $cap !important;
}
.bank.new-account .container.sitecontainer.payment-left-main .MuiGrid-root{
    max-width: none !important;
    margin: 0 !important;
}
.bank.new-account .profile-change .MuiFormControl-root {
    width: -webkit-fill-available !important;
}
.bank.new-account .profile-change.submit .MuiFormControl-root {
    width: fit-content !important;
}
.bank.new-account .container.sitecontainer{
    margin: 20px 10px;
}
