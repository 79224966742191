.copytrade-tab button.MuiButtonBase-root {
    /* font-family: Roboto; */
    font-size: clamp(20px,3vw,24px);
    font-weight: 400;
    line-height: 33.6px;
    text-align: left;
    color: #8B8B8E;
    text-transform: capitalize;

}

.copytrade-tab button.MuiButtonBase-root.Mui-selected {
    /* font-family: Roboto; */
    font-size: clamp(20px,3vw,24px);
    font-weight: 400;
    line-height: 33.6px;
    text-align: left;
    text-transform: capitalize;
    color: var(--clr-font);
    /* border-bottom: 2px solid var(--clr-font); */

}

.copytrade-tab button.MuiButtonBase-root.Mui-selected::before {
    content: '';
    position: absolute;
    background-color: var(--clr-font);
    display: flex;
    justify-content: center;
    transition: 0.3s;
    height: 3px;
    width: 60px;
    bottom: 0;
}

.copytrade-tab .MuiTabs-indicator {
    display: none;
    /* left: 77px !important; */
    /* width: 50px !important; */
    /* background: var(--clr-font); */
}

.cpy-h {
    /* font-family: Roboto; */
    font-size: clamp(28px, 3vw, 32px);
    font-weight: 400;
    line-height: 44.8px;
    text-align: left;
    color: #FFFFFF;

}
body.light-theme .cpy-h{
    color: #000;
}
.copytrade .card_box {
    margin-top: 40px;
    margin-bottom: 75px;
}
.copytrade .card_box .contain-width{
    padding: 0 !important;
}

.copytrade-banner {
    background: var(--cpy);
    padding-top: 50px;
    padding-bottom: 60px;
    margin-bottom: 50px;
}

.copy-headung {
    /* font-family: Roboto; */
    font-size: clamp(36px ,3vw,48px);
    font-weight: 400;
    line-height: 56px;
    text-align: left;
    color: var(--clr-font);

}

.copy-h-tbn {
    /* font-family: Roboto; */
    font-size: 16px;
    font-weight: 400;
    line-height: 22.4px;
    text-align: left;
    color: var(--clr-font);
}

.copy-h-para {
    /* font-family: Roboto; */
    font-size: 20px;
    font-weight: 400;
    line-height: 32px;
    text-align: left;
    color: var(--clr-font);
}

.lgIn {
    /* font-family: Roboto; */
    font-size: 14px;
    font-weight: 400;
    line-height: 19.6px;
    text-align: left;
    color: var(--clr-font);

}

.copy-sub-p {
    /* font-family: Roboto; */
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #8B8B8E;

}


.flow {
    /* font-family: Roboto; */
    font-size: 28px;
    color: var(--clr-font);
    font-weight: 400;
    line-height: 39.2px;
    text-align: left;

}

.pf-share {
    /* font-family: Roboto; */
    font-size: 16px;
    font-weight: 400;
    line-height: 22.4px;
    text-align: left;
}

.tutoril {
    /* font-family: Roboto; */
    font-size: 16px;
    font-weight: 400;
    line-height: 22.4px;
    text-align: left;
    color: #FF7028;

}

.use-levg {
    /* font-family: Roboto; */
    font-size: 14px;
    font-weight: 400;
    line-height: 19.6px;
    text-align: left;
    color: #FF7028;
    max-width: 75%;

}

.use-levg-banr {
    background: #F8701B14;
    border-radius: 12px;
}

.lgSub {
    color: #777989;
    /* font-family: Roboto; */
    font-size: 12px;
    font-weight: 400;
    line-height: 16.8px;
    text-align: left;
}

.email-name.mod,
.email-fill.mok input, .email-fill.mok textarea {
    color: var(--clr-font) !important;
}

.submit button {
    background: #fe7701 !important;
    font-size: 13.89px;
    font-weight: 600;
    line-height: 28px;
    text-align: center;
    color: #ffffff;
    text-transform: capitalize;
    height: 30px;
    border-radius: 6px;
}

.cP_flx{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    flex-wrap: wrap;
}
.copy_box {
    border-radius: 8px;
    background: #e3e3e3;
    padding: 15px;
    font-weight: 400;
    font-size: 12px;
    max-width: 300px;
    color: var(--clr-font);
}
.copy_box .big {
    font-weight: 400;
    font-size: 18px;
    margin: 15px 0;
}
.dark-theme .copy_box {
    color: #000;
}

.accr_b::before{
    display: none;
} 
.or_b{
    color: #fff !important;
    fill: #fff !important;
    background: rgb(254, 119, 1) !important;
    border-radius: 8px;
    font-weight: 500;
    width: 100%;
    padding: 10px;
    border: 0;
    cursor: pointer;
}
.tag_box {
    background: #00000017;
    padding: 5px;
    border-radius: 5px;
    margin: 3px;
    font-size: 12px;
}
.future_h{
    font-size: clamp(28px, 3vw, 32px);
    font-weight: 400;
    line-height: 44.8px;
}
.dark-theme .future_h{
    color: #fff;
}
.profit_h{
    background-color: #ccc;
    width: fit-content;
    padding: 5px;
    display: flex;
    align-items: center;
    gap: 6px;
    border-radius: 5px;
    font-size: 14px;
}
.copytrade .ylw{
    color: #bd8e64;
}
.dark-theme .prf_box{
    color: #e0d8d8;
}
.prf_box .p_flx{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 15px;
}
.prf_box .c_box{
    border: 1px solid #d5d5d5;
    border-radius: 12px;
    padding: 12px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 20px;
    justify-content: space-between;
    max-width: 340px;
}
.prf_box .c_box h4{
    font-size: 24px;
    font-weight: 600;
    margin: 10px 0;
}
.prf_box .c_order{
    max-width: 340px;
    color: #8b8b8e;
}
.tab_ctn > div{
    padding: 0;
}
.tab_h button{
    padding-left: 0;
    font-size: 16px !important;
}
.dark-theme .tab_h button.Mui-selected .display-1{
    color: #fff !important;
}
.sel_box .MuiSelect-select{
 padding: 10px;
}
.check_b path{
 fill: #a4a4a4 !important;
}
.check_b input:checked + svg path{
    fill: #6a6464 !important;
   }
.left_b{
    margin-bottom: 15px;
}
.left_b,.left_b div{
    color: #8b8b8e; 
    font-size: 14px;
    line-height: 21px;
}
.left_b label{
    font-size: 14px;
}
.left_b .MuiSelect-select{
    color: #000;
}
.dark-theme .left_b .MuiSelect-select{
    color: #fff;
}
.bg_tran{
    /* background-color: #cccccc; */
    border-radius: 5px;
    margin-bottom: 15px;
}
.dark-theme .bg_tran {
    /* background-color: #454648; */
}

.dark-theme .bg_tran .ico{
    filter: invert(1);
}
.dark-theme .bg_tran .css-1i5ruty-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root{
    background-color:transparent;
}