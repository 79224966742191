@import '../../Scss/main.scss';
@import '../../Css/theme.css';

.twofa-verify .ticket-box {
    max-width: 650px;
    margin: 0 auto;
    border-radius: 10px;
}
.twofa-verify .ticket-box h2 {
    margin: 0 0 15px;
    color: var(--clr-font);
}
ul.auth-steps {
    padding-left: 15px;
}
ul.auth-steps li {
    list-style: decimal;
    padding-bottom: 15px;
    color: var(--clr-font);
}
.qr-bar img {
    width: 150px;
}
.qr-bar {
    display: flex;
    flex-direction: column;
    gap: 15px;
    justify-content: center !important;
    align-items: center;
    color: var(--clr-font);
}
.fa-code {
    display: $flex;
    align-items: $center;
    gap: 5px;
    color: var(--clr-font);
    margin: $mar-15px 0;
}
.confirm-cancel {
    margin-top: 25px;
    display: $flex;
    justify-content: center;
}
body.dark-theme .twofa-verify fieldset.MuiOutlinedInput-notchedOutline{
   border: 1px solid #dedede;
}
.twofa-verify input{
    padding: 13px 14px;
    color: var(--clr-font) !important;
    text-overflow: ellipsis;
}
.twofa-verify h1 {
    text-align: center;
    margin: 0 0 20px;
}
 .ivon-back {
    margin: 25px 30px 0;
}
.ivon-back img{
    width: 33px;
}
.copy-id fieldset.MuiOutlinedInput-notchedOutline{
    border: none !important;
    display: flex;
}
.copy-id {
    border: 1px solid #dedede;
    border-radius: 6px;
    width: 450px;
    width: 100%;
    display: $flex;
    align-items: $center;
    color: var(--clr-font);
    justify-content: space-between;
}
.confirm-cancel button:first-child{
    background: $btn-primary;
}