@import "../../Scss/main.scss";

.copysetting-banner h1{
    color: $btn-primary;
    font-size: clamp(23px, 5vw, 35px);
}
.copysettings-full .ivon-back{
    margin: 0;
}
.setting-details-cnt h2 {
    font-size: 25px;
    color: var(--clr-font);
}
.setting-details-cnt h2 {
    font-size: 25px;
}
.setting-details-cnt button.Mui-selected {color: rgba(255, 112, 40, 1);}
.setting-details-cnt button {
    font-weight: 600;
    font-size: 15px;
    color: var(--clr-font);
    padding: 0 15px 0 0;
}
.setting-details-cnt span.MuiTabs-indicator {
display: none;
}
.setting-details-cnt{
    color: var(--clr-font);
}
.setting-details-cnt .MuiBox-root {
    padding: 20px 0;
}
.ratio-amount-field .MuiInputBase-root {
    margin-top: 15px;
}
.ratio-amount-field fieldset {
    border-radius: 15px;
    border-color: var(--card-bg) !important;
}
.ratio-amount-field input {
    background: var(--card-bg);
    padding: 9px !important;
    color: var(--clr-font);
    border-radius: 15px;
    height: 34px;
}
.ratio-amount-field {
    margin-top: 15px;
}
.ratio-amount-field .MuiFormControl-root{
    margin-bottom: 15px;
}
.advanced-setting .MuiPaper-root {
    background: transparent;
    box-shadow: none;
    color: var(--clr-font);
}
.advanced-setting svg {
    color: var(--clr-font);
}
.advanced-setting .MuiButtonBase-root, .advanced-setting .MuiAccordionDetails-root{
    padding: 0;
}
.ratio-amount-field label {
    font-size: 19px;
}

