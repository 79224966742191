@import '../../Scss/main.scss';


.we-are {
    font-family: Sansation;
    font-size: 41.06px;
    font-weight: $weight-400;
    // line-height: 45.96px;
    text-align: $left;
    color: #3B6CE4;
}

.underconst-main-div {
    display: $flex;
    align-items: $center;
    height: 90vh;
    margin: 0px 15px;
    justify-content: $justi-sa;
}

.under {
    font-family: Sansation;
    font-size: 128px;
    font-weight: $weight-700;
    // line-height: 143.25px;
    text-align: $left;
    color: #3B6CE4;
}

.construct-text {
    font-family: Sansation;
    font-size: 124px;
    font-weight: $weight-400;
    // line-height: 138.77px;
    text-align: $left;
    color: #80A5FA;

}

.main-banner-div {
    position: absolute;
    // top: 15%;
    left: 3%;
}

.main-banner-div.new {
    position: absolute;
    top: 15%;
    left: 3%;
}

.underconst-main-div.new {
    justify-content: flex-end !important;
}

.return-home {
    font-family: Sansation;
    font-size: 64px;
    font-weight: $weight-400;
    // line-height: 71.63px;
    margin: 15px 0px;
    text-align: $left;
    background: #3B6CE4;
    color: #fff;
    padding: $pad-10px;
    border-radius: $radius-12px;
}

.construct-image img {
    width: 100%;
    height: 100%;
}