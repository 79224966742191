@import "../../Scss/main.scss";

.buy-sell-main-tab,
.buy-sell-main-tab2{
  // background-color: var(--box-bg);
  color: var(--clr-font);
  padding: 0 $pad-15px;
  // border-radius: $radius-12px;
  // margin-top: $mar-25px;
  // box-shadow: var(--box-shadow);
  // border-bottom: 1px solid #e9e7e7;
}

.add-payment-main{
  color: var(--clr-font);
  padding: $pad-15px;
  border-radius: $radius-12px;
  margin-top: $mar-25px;
}

.buy-sell-main-tab span.MuiTabs-indicator {
  background-color: unset !important;
}
.all-coin-box .MuiBox-root {
  padding: 0;
}
.display-1.table-top {
  margin-bottom: 25px;
  overflow: scroll;
}

.buy-sell-main-tab .MuiTabs-flexContainer {
  background-color: rgba(32, 34, 38, 1);
  width: fit-content;
  border-radius: $radius-8px;
  // padding: $pad-10px;
}
body.light-theme .buy-sell-main-tab .MuiTabs-flexContainer{
  background: #fff;
  border: 1px solid #ccc;
  padding: 5px;
  height: 35px;
}
.buy-sell-text.display-2 {
  margin-top: 35px;
  border-bottom: 1px solid rgb(224, 224, 224);
  flex-wrap: wrap;
  padding: 0 0 20px;
  gap: 5px;
}

.buy-sell-text button.Mui-selected {
  background-color: #fe7701;
  color: #fff;
  border-radius: $radius-6px;
}
 .buy-sell-main-tab {
  margin: 0 20px;
}

.buy-sell-text button {
  min-height: 35px;
  color:var(--clr-font);
  min-height: 37px !important; 
}

.main-tab-right {
  background-color: $btn-primary;
  padding: 8px 22px;
  border-radius: $radius-8px;
  cursor: pointer;
  font-size: 15px !important;
  color: #fff !important;
}

.main-tab-right div {
  display: $flex;
  align-items: $center;
  gap: 5px;
  cursor: pointer;
}

.usr {
  color: #fe7701;
  font-size: $font-16;
  font-weight: $weight-600;
}

.order {
  font-size: 12px;
  color: var(--clr-font);
}

.p2p-table-price {
  font-weight: $weight-700;
  color: var(--clr-font);
}

.aval {
  gap: $gap-10 !important;
  margin: 5px 0px;
  justify-content: $center !important;
}
.chip-div .MuiChip-root {
  background: #fe7701;
}
.buy-sell-main-tab2 button, .buy-sell-main-tab2 button:hover{
  background: #fe7701;
  white-space: nowrap;
  color: #fff;
}
.red_bt span{
  background-color: #cc3939;  
  z-index: -1;
}
.buy-sell-main-tab2 .sellusdt button span{
  background-color: #cc3939;
  z-index: -1;
}
.aval2 {
  gap: $gap-10 !important;
  margin: $mar-25px 0;
  justify-content: flex-start !important;
}
.aval2 .MuiChip-filled {
  padding: 7px 9px;
}
body.dark-theme .aval2 .MuiChip-filled {
  background-color: rgb(52 52 52 / 76%);
}

.aval2 span,
.aval span {
  color: var(--clr-font) !important;
}

.buyusdt button {
  color: #fff;
  padding: 5px 28px;
}
.buyusdt span{
  background: #20b26c;
  z-index: -1;
}
.buy-sell-text.display-1{
  // border-bottom: 1px solid rgba(224, 224, 224, 1); 
  flex-wrap: nowrap;
  align-items: baseline;
}
.buy-sell-text.display-1 .table-top{
  width: 100%;
  align-items: center;
}
.gren_bt span{
  z-index: -1;
  background: #20b26c; 
}
.light-theme .refre_sh{
  border: 1px solid rgb(127 132 139 / 25%) !important;
}
.refre_sh{
  border: 1px solid rgb(234 236 239 / 25%) !important;
  padding: 9px 14px;
  border-radius: 50px;
}
body.dark-theme .p2p-full-cnt th, body.dark-theme .p2p-full-cnt td,body.dark-theme .postnew-ad .MuiTabs-flexContainer, body.dark-theme .Wallet table th,
body.dark-theme .buy-sell-text.display-2{
  border-bottom: 1px solid #2B3139 !important;
}

.table-prfile-text.pop .usr {
  margin: $mar-10px 0px;
  font-size: 18px;
}

.table-prfile-text.pop .order {
  font-size: 16px;
}
.order_det.time-details {
  gap: 60px;
}
.order_det.time-details .order{
  font-weight: 600;
  font-size: 16px;
}
.time-details {
  margin: $mar-25px 0;
  color: var(--clr-font);
  gap: 40px;
}
.makepay-main div {
  margin-top: 12px;
}
.stepper-line .MuiStepConnector-line{
display: none !important;
}
.chat-space{
  height: 60vh;
}
.order-details .ticket-box {
  border: 1px solid #ccc;
  margin-bottom: 15px;
  border-radius: 8px;
}
.pop-desc {
  margin: $mar-15px 0px;
  font-weight: $weight-500;
  color: var(--clr-font);
}
.stepper-line span.MuiStepLabel-root {
  align-items: baseline !important;
}
.stepper-line svg{
  position: relative;
  top: 9px;
}
.stepper-line svg{
  color: #fe7701 !important;
}
.pop-desc-main {
  padding: $pad-15px;
  border-radius: $radius-12px;
  border: 1px solid #ccc;
  // border-right: 5px solid #ccc;
}

.pop-right-main .price {
  margin: $mar-15px;
  color: var(--clr-font);
}

.will-pay {
  background-color: #ccc;
  background-color: #f3f5f7 ;
  color: var(--clr-font);
  border-radius: $radius-12px;
  padding: $pad-10px;
  margin: $mar-15px;
}

.pop-right-input fieldset {
  border: unset !important;
}

.pop-right-input input {
  padding: 0px !important;
}

.will-pay-text {
  color: #000;
  padding: $pad-10px;
}

.display-3.gap {
  gap: $gap-10;
  // justify-content: $justi-se !important;
}

.buy-sell-main-tab2 th {
  background-color: transparent;
  color: var(--clr-font);
  font-weight: 600;
}

.post-ad-title {
  color: var(--clr-font);
  margin: $mar-15px;
  font-size: $font-25;
  padding: $pad-10px;
  font-weight: $weight-700;
  position: relative;
}

.post-ad-title svg {
  position: absolute;
  left: 2%;
  cursor: pointer;
}

.postnew-ad Button {
  width: 100%;
  color: var(--clr-font) !important;
}

.postnew-ad {
  margin: $mar-30px 0px;
}
.terms-lines li {
  margin-bottom: 15px;
  font-size: 15px;
  list-style-type: disc !important;
}
.postnew-ad Button.Mui-selected {
  color: #fe7701 !important;
  border-radius: $radius-8px;
}

.postnew-ad .MuiTabs-flexContainer {
  justify-content: $justi-sb;
  border-bottom: 1px solid #ccc;
}
.table-top {
  margin-bottom: 25px;
}

.postnew-ad .MuiTabs-indicator {
  background-color: unset !important;
}

.asset-main {
  margin: $mar-20px 0px;
}

.multi-select .MuiSelect-select,
.pric-vol input {
  padding: $pad-10px;
  color: var(--clr-font);
}

.pric-vol-text {
  margin: $mar-10px 0px;
}

.pric-vol .MuiFormControl-root {
  width: 90%;
}

.pric-vol fieldset {
  border: 1px solid var(--clr-font);
}
.id-popup.terms-modal.MuiBox-root.css-0 {
  height: 70vh;
  overflow: scroll;
}
.p2p-pop.id-popup{
  width: 500px;
}
.dark-theme .p2p-pop.id-popup .close-menu,
.dark-theme .p2p-pop.id-popup h2.MuiTypography-root{
  background: #272727;
}
.p2p-pop.id-popup .close-menu{ 
  position: sticky;
  top: -14px;
  background: #fff;
  z-index: 2;
  padding-top: 10px;
  width: fit-content;
  margin-left: auto;
}
.p2p-pop.id-popup h2.MuiTypography-root{
  position: sticky;
  top: -30px;
  background: #fff;
  z-index: 1;
  text-align: center;
  font-weight: 600;
  margin-top: -50px;
  padding-top: 20px;
  padding-bottom: 10px;
}
.cfrm-btn button, .cfrm-btn button:hover {
  background: rgb(255, 112, 40);
  color: #fff !important;
  width: 100%;
  margin-top: 12px;
}



.chip-div,
.Payment-text {
  margin: $mar-10px;
  padding: $pad-10px;
  color: var(--clr-font);
}

.Payment-text {
  font-weight: $weight-700;
}

.payment-para {
  font-size: $font-14;
  // max-width: $percent-60;
  padding: 0px $pad-20px;
  color: var(--clr-font);
}

.add-pay {
  padding: $pad-10px;
  border-radius: $radius-12px;
  background-color:$btn-primary;
  // width: max-content;
  cursor: pointer;
  font-size: 15px !important;
    white-space: nowrap;
    color: #fff !important;
}

.select-payment-pop-text {
  font-size: $font-20;
  font-weight: $weight-800;
  color: var(--clr-font);
}

.recommend {
  // padding: $pad-15px;
  font-weight: $weight-800;
  margin: $mar-25px 0;
  color: var(--clr-font);
}

// .method-stack hr {
//   border: 1px solid;
//   border-color: var(--clr-font);
// }
.method-stack .MuiPaper-root {
  background: transparent;
  box-shadow: none;
  border-left: 3px solid rgb(254, 119, 1);
  padding: 5px;
  border-radius: 0;
  line-height: 0.5;
}
.method-stack > div {
  overflow: scroll;
  white-space: nowrap;
}
.paymeny-type-text {
  border-left: 3px solid $btn-primary;
  padding: 5px;
  font-weight: $weight-900;
}

.add-pay-input-main {
  margin: $mar-25px 0px;
}
.close-menu.text-end.cursor svg path{
  fill: var(--clr-font);
}
.add-pay-input-main .text {
  font-weight: $weight-800;
  margin: $mar-10px 0px;
  color: var(--clr-font);
}

.qr-image-upload img {
  width: 100px;
  height: 100px;
}

.addpay-confm {
  margin: $mar-20px 0px;
}
.addpay-confm button, .addpay-confm button:hover{
  background: $btn-primary;
}
.postnew-ad.buy-sell-main-tab2 button {
  background: transparent;
  color: #000 ;
  font-weight: 700;
}
.asset-main .display-3 {
  height: 14vh !important;
}

.added-beneficy-main {
  margin: $mar-40px $mar-20px;
  border: 1px solid #ccc;
  // padding: $pad-20px;
  border-radius: $radius-10px;
}
.post-ad button {
  color: #fff !important;
  margin: $mar-25px 0px;
  background: #fe7701 !important;
}
.paymeny-type-text.main {
  text-transform: $upper;
}

.added-list {
  background-color: var(--card-bg);
  padding: $pad-10px;
  border-radius: $radius-10px $radius-10px 0px 0px;
}

.beneficary-in-detail {
  display: $flex;
  justify-content: $justi-sb;
  gap: $gap-30;
  padding: $pad-25px;
}

.beneficary-in-detail .usrname {
  font-weight: $weight-800;
  margin: $mar-10px 0px;
  color: var(--clr-font) !important;
}
.P2P .aval{
  color: var(--clr-font) !important;
}
.beneficary-in-detail .name {
  color: #ccc;
  margin: $mar-10px 0px;
}
.Myads table td{
  color: var(--clr-font);
}
.Myads table th{
  background-color: transparent;
  color: var(--clr-font);
  white-space: nowrap;
}
body.dark-theme .Myads table th, body.dark-theme .Myads table td{
  border-bottom: 1px solid #2B3139 !important;
  white-space: nowrap;
}
// .iner-bg-row {
//   background-color: #ccc;
// }
.myads-full {
  padding: 0 30px;
}
.order-details-mian {
  background-color: var(--card-bg);
  color: var(--clr-font);
  padding: $pad-15px;
  margin: $mar-15px;
  border-radius: $radius-8px;
  flex-wrap: wrap;
}

.stepper-line{
  padding: 0 10px;
}
.order-num {
  font-size: $font-14;
  color: #a8a6a6;
  margin: $mar-10px;
}

.order-num span {
  font-size: $font-14;
  margin: 0px 5px;
  color: var(--clr-font);
  font-weight: $weight-900;
}

.order-cancel-text {
  font-size: $font-18;
  font-weight: $weight-800;
  color: var(--clr-font);
}

.make-pay-inner {
  height: 200px;
  overflow-y: scroll;
}
.make-pay-inner >div{
  display: flex;
  align-items: flex-start;
  border: 1px solid #E6E8EA;
  flex-wrap: wrap;
}
.dark-theme .make-pay-inner >div{
  border: 1px solid #333;
}
.make-pay-inner >div .added-list{
  width: 200px;
  border-radius: 0px;
  margin-top: 0;
  padding: 5px 10px;
}
.make-pay-inner >div .added-list div,
.make-pay-inner >div .added-list + div{
  margin-top: 0;
}
.make-pay-inner > div .added-list + div {
  border-left: 1px solid #E6E8EA;
}
.dark-theme .make-pay-inner > div .added-list + div {
  border-left: 1px solid #E6E8EA;
}
.make-pay-inner >div .added-list .paymeny-type-text{
  padding: 0;
  padding-left: 5px;
}
.make-pay-inner .ticket-box{
  min-width: 200px;
}
.make-pay-inner .ticket-box.up_load{
  border: 0;
  margin-left: 10px;
}
.light-theme .make-pay-inner .ticket-box.up_load{
  background-color: #aaaaaa2b !important;
}
.ad_tab button{
  min-width: 50%;
  text-align: center;
}
.makepay-main {
  color: var(--clr-font);
  padding-left: 40px;
  border-left: 2px solid rgb(247 153 72);
  position: relative;
  right: 20px;
  top: 26px;
}
.sti_ck{
  position: sticky;
  top: 55px;
  padding-top: 5px;
  background: #f5f5f5;
  z-index: 10;
}
.dark-theme .sti_ck{
  background: #121212;
}
.myads-table-div.d-flex {
  justify-content: space-between;
  align-items: center;
  display: flex;
}
.myads-table-div .dtime {
  font-size: 12px;
  font-weight: 600;
  color: rgb(112, 122, 138);
  position: relative;
}
.dark-theme .dtime::before {
  background: #fff !important;
  width: 1px;
}
.myads-table-div .dtime::before {
  position: absolute;
  content: "";
  background: rgb(112 122 138 / 45%);
  width: 2px;
  height: 9px;
  top: 3px;
  left: -7px;
  /* margin: 0 10px; */
}
.myads-table-div span.verify {
  background-color: rgba(247, 147, 27, 1);
  color: #fff !important;
  padding: 5px;
  border-radius: 10px;
  font-weight: 500;
}
.myads-table-div .Payment-Methods-main-card-two {
  display: flex;
  gap: 16px;
  align-items: center;
}
.myads-table-div .Payment-Methods-main-card {
  display: flex;
  gap: 5px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  padding: 0 5px;
}
.dark-theme .myads-table-div .Payment-Methods-main-card {
  border: 1px solid #2b3139;
}
.myads-table-div .Payment-Methods-main-card span {
  color: rgba(247, 147, 27, 1) !important;
  border-radius: 10px;
  font-weight: 500;
}
.myads-table-div .Payment-Methods-main-card svg{
  width: 14px;
  margin: 0px 4px -5px;
}
.myads-table-div .Payment-Methods-main-card path {
  fill: rgb(112, 122, 138);
}
.td-flex{
  display: flex;
      gap: 5px;
      align-items: center;
      border: 0;
}
.tdfiat{
  font-weight: 600;
}
.theme-clr, .theme-clr a, .theme-clr, .theme-clr a:hover {
  color: #F7931B !important;
  font-weight: 600;
}
.mui-list ul.MuiMenu-list {
  padding: $pad-10px $pad-20px;
}

.mui-list .MuiPaper-root.MuiPaper-elevation {
  border-radius: $radius-12px;
  // top: 55px !important;
  background-color: var(--clr-bcg);
  color: var(--clr-font);
  box-shadow: var(--box-shadow);
}

.p2p-select .MuiSelect-select {
  color: var(--clr-font);
}

.p2p-select fieldset {
  border: 1px solid #ccc !important;
}
body.dark-theme .p2p-select fieldset, body.dark-theme .search-input fieldset{
border: 1px solid #2B3139 !important;
}

.p2p-select svg {
  fill: var(--clr-font);
}

.Myads button.Mui-selected,
.Tradepage button.Mui-selected svg {
  color: #fe7701 !important;
  // fill: rgb(39, 170, 225) !important;
}

.Myads button.MuiTab-root {
  color: var(--clr-font);
  min-width: 70px;
  font-weight: 700;
}

.Myads span.MuiTabs-indicator {
  background-color: unset !important;
}
.pop-right-main button {
  width: 100%;
  height: 49px;
}
.pop-right-main .display-3.gap {
  margin: 15px;
}
 span.MuiButtonBase-root {
  color: var(--clr-font);
}
.cancel-btn{
  // background:#fe7701 !important;
  color: var(--clr-font) !important;
  border: 1px solid #fe7701 !important;
}
.transfer-notify {
  margin: 50px;
  display: flex;
  justify-content: center;
  gap: 10px;
}
.transfer-notify button {
  width: 100%;
  // height: 46px;
}
.transfer-notify button span{
  background: #ff0000eb;
  z-index: -1;
}
.transfer-notify button:first-child {
  background: #fe7701;
  color: #fff;
}
.order-details .msglist .colab-chat-area.msg {
  height: 548px;
}
.order-details{
  padding-bottom: 40px;
}
.order-details .msglist{
  background: var(--box-bg);
  box-shadow: none !important;
  border: 1px solid #d0d3d7;
  margin-bottom: 10px;
}
.time_r {
  display: flex;
  gap: 5px;
  margin-top: 7px;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
}
.time_r span{
  border-radius: 4px;
  background: rgb(247, 147, 27);
  padding: 5px 7px;
  color: #fff;
  font-weight: 600;
  width: 20px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.P2P .aval .MuiChip-root {
  padding: 7px 9px;
}
body.dark-theme .P2P .aval .MuiChip-root {
  background-color: rgb(38 37 37 / 76%);
}
.p2p-full-cnt .table-top{
  overflow: scroll;
  align-items: baseline;
}
// .p2p-full-cnt{
//   padding: 0 40px;
// }
.p2p-full-cnt .buy-sell-main-tab2 .MuiFormControl-root{
  min-width: 250px !important;
}

.usdt-btc .MuiTabs-flexContainer {
  background: transparent !important;
  border: none !important;
}
.usdt-btc 
button.Mui-selected {
  color: #000;
  border: 1px solid;
  border-radius: 90px;
  background: #ffff;
}
.usdt-btc button {
  padding: 0 !important;
  min-height: 36px;
  color: var(--clr-font);
}





.added-beneficy-main {
  // display: table;
  // width: 100%;
  margin: 0;
  margin-top: 20px;



  // width: fit-content;
  margin: 0;
  margin-top: 20px;
  // max-width: fit-content;
  // padding-right: 25px;

  white-space: nowrap;
}

// .added-beneficy-main > div{
//   display: table-row;
// }

// .added-beneficy-main > div >div{
//   display: table-cell;
// }


.set-def{
  background: #fe7701;
  font-size: 13.89px;
  font-weight: 600;
  line-height: 28px;
  padding: 5px 20px;
  text-align: center;
  color: #ffffff;
  text-transform: capitalize;
  height: 25px;
  border-radius: 6px;
}