.dark-theme {
    --clr-font: #fff;
    --clr-bcg: #000;
    --clr-bk: #1B1B1B;
    --clr-headebg: #000;
    --bg-contain: #121318;
    --clr-box: #121318;
    --clr-border: 1px solid #ccc;
    --box-shadow: rgba(255, 255, 255, 0.363) 0px 5px 15px;
    --bdr-btm: rgb(103 101 101);
    --clr-card: #272727;
    --bg-input: #121318;
    --bg-dark-card: rgba(23, 23, 23, 1);

    --bdr-colr: #292929;
    --bdr-colr1: rgba(243, 243, 243, 0.18);
    --grey: #f3f3f366;
    --grey1: #f3f3f366;
    --green: #01BC8D;
    --red: #F65454;
    --model: #1f1f1f;
    --invert: invert(0);
    --body-bg: #121212;
    --card-bg: rgb(50, 50, 50);
    --input: #F3F3F366;
    --clr-grey: rgba(255, 255, 255, 0.16);
    --clr-btn: #F3F3F30A;
    --box-bg: #272727;
    --support: #25262c;
    --cpy: #222222;
    --brbtm: #f3f3f3de;
    --mdl: #212225;
}




.light-theme {
    --clr-font: #000;
    --clr-bcg: #fff;
    --clr-headebg: #6b7280;
    --clr-bk: #effbff;
    --bg-contain: #fff;
    --clr-box: #f6f6f6;
    --clr-border: 1px solid #dedede;
    --box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    --bdr-btm: rgba(224, 224, 224, 1);
    --clr-card: #fff;
    --bg-input: rgba(150, 198, 255, 1);
    --bg-dark-card: #fff;
    --card-bg: rgb(50, 50, 50);
    --bdr-colr: #fe7701;
    --bdr-colr1: rgba(0, 0, 0, 0.13);
    --grey: #00000066;
    --grey1: #000;
    --green: #01BC8D;
    --red: #F65454;
    --invert: invert(1);
    --clr-grey: #ccc;
    --body-bg: #f5f5f5;
    --model: #1f1f1f;
    --card-bg: rgba(232, 232, 232, 1);
    --input: #000;
    --clr-btn: #0000000A;
    --box-bg: #f3f3f3de;
    --support: #fff;
    --cpy: #f3f3f3de;
    --brbtm: #fe7701;
    --mdl: #fff;
}