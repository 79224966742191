/*Media query for max-width*/
/* @import '../Scss/main.scss'; */

@media (max-width:575.98px) {
    .profile-sec {
        flex-direction: column;
    }

    .setup {
        justify-content: center !important;
    }

    .card-part {
        width: 100%;
    }

    .time-details {
        flex-direction: column;
        gap: 15px;
        align-items: flex-start !important;
    }

    .main-banner-div,
    .main-banner-div.new {
        left: 0px !important;
        right: 0px !important;
        overflow: scroll;
    }

    .id-popup {
        width: 80% !important;
    }

    .payment .ticket-box {
        padding: 10px;
    }

    .Login {
        height: 100% !important;
    }
}
@media (max-width:599.98px) {
    .sti_ck{
        top: 35px !important;
    }
}
@media (max-width:767.98px) {
    .pagnation li button{
        font-size: 12px;
        min-width: 22px;
        height: 22px;
        margin: 0 0.9px;
        border-radius: 3px !important;
    }
    .view-all  .css-10w330c-MuiButtonBase-root-MuiPaginationItem-root{
        min-width: 22px;
        height: 22px;
        margin: 0;
        font-size: 12px;
    }
    .buy-sell-main-tab,
    .main-payment-right {
        flex-direction: column !important;
        gap: 15px;
    }

    .id-popup.google-auth-pop.MuiBox-root.css-0 {
        height: 77vh;
        overflow: scroll;
    }

    .display-2.gap.main-payment-right {
        flex-wrap: wrap;
        flex-direction: row !important;
    }



    .postnew-ad Button {
        width: unset !important;
    }

    .under,
    .construct-text,
    .return-home,
    .we-are {
        text-align: center !important;
    }

    /* .sidebar {
        width: 100% !important;
    } */


}

@media (max-width:767.98px) {

    .gainer-history,
    .referral-info {
        grid-template-columns: repeat(1, 1fr) !important;
    }

    .history-inputs .MuiFormControl-root {
        width: 100% !important;
        min-width: 100% !important;
    }

    .we-are {
        font-size: 25px !important;
    }

    .under {
        font-size: 53px !important;
    }

    .construct-text {
        font-size: 50px !important;
    }

    .return-home {
        font-size: 27px !important;
    }

    .market_overview .box {
        padding: 20px 10px;
    }

    .market_overview .box li>div {
        font-size: 13px;
    }

    .assets-order.display-1 {
        justify-content: flex-start !important;
    }

    .banner .input_grp {
        width: 98% !important;
    }

    .display-1.add-payment-check {
        flex-wrap: wrap;
        justify-content: center;
    }

    .add-pay {
        margin-top: 25px;
    }

    .wallet-msg:after {
        display: none !important;
        ;
    }

    .pencil-icon {
        top: 68px !important;
    }

    .security-tab.display-2 .MuiTabs-root {
        overflow: scroll;
    }
    .card_box .box .f_box{
        font-size: 15px;
    }
}

@media (max-width:991.98px) {
    .copy_ban {
        flex-direction: column;
    }
    .copy_ban .copy-h-para,.copy_ban .copy-sub-p{
      text-align: center;
    }
    .m_center{
        justify-content: center !important ;
    }
    .ticket-box {
        height: auto !important;
    }

    .ticket-box.market-tab .MuiTabs-scroller,
    .market-current-history .MuiTabs-scroller {
        overflow-x: scroll !important;
    }

    .banner h1 {
        font-weight: 700;
        font-size: 32px !important;
        line-height: unset !important;
    }

    .banner .input_grp a {

        padding: 0 10px;
        white-space: nowrap !important;
    }
    .market-current-history .tab_bdr {
        margin-bottom: 0 !important;
    }

}

@media (max-width:1199.98px) {
    .buy-sell-text.display-1{
        flex-direction: column;
    }
    .buy-sell-text.display-1 .buy-sell-main-tab2{
        padding-left: 0;
    }
    .cP_flx .input_grp{
        margin-left: 20px;
        margin-right: 20px;
    }
    .login-h-btn{
        margin-top: 0 !important;
    }
    .card-container {
        flex-wrap: wrap;
        justify-content: flex-start !important;
        gap: 24px !important;
    }

    .trade-history .searchfrm {
        display: flex !important;
        flex-wrap: wrap !important;
    }

    .trade-history .MuiTabs-scroller {
        overflow-x: auto !important;
    }

    .logo img {
        width: 150px;
    }

    /* .drawer .MuiDrawer-paper {
        width: 100%;
    } */

    .whole-list {
        flex-direction: column;
    }

    footer .link_flx {
        flex-wrap: wrap;
    }

    .why_choose .flx {
        flex-direction: column;
    }

    .why_choose .flx>div {
        width: 84% !important;
        margin: 0 auto;

    }

    .copy_trading .start {
        margin-left: 0 !important;
        margin-top: 20px;
    }
    .banner {
        background-position: top !important;
    }
    .banner h1 {
        font-size: 32px;
    }

    .build .h_2+div>div {
        padding-left: 0;
    }

    .why_choose .flx h6 {
        font-size: 22px !important;
    }

    .copy_trading h2 {
        font-size: 36px !important;
    }

    .home_secs_chg>div>div {
        flex-direction: column-reverse;
    }

    .searchfrm {
        flex-wrap: wrap !important;
    }

    .why_choose .flx p {
        display: block !important;
    }

    .order-details .ticket-box {
        margin: 45px 20px !important;
    }
}

/*Media query for min-width*/

@media (width <=575.98px) {
    .container.sitecontainer {
        padding: 20px !important;
        overflow-x: hidden;
        WIDTH: 100%;
    }

    .cryptosaving .saving-page .enough {
        flex-direction: column;
    }

    .build h2,
    .h_2 {
        font-size: 28px !important;
    }
}

@media (width >=575.98px) {}

@media (width <=767.98px) {
    .payment .payment-left-main .display-1 {
        flex-direction: column;
        gap: 21px;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .stackingbannerright {
        width: 100% !important;
    }

    .stackingbannerright .card,
    .card-stacking .eth-card-total {
        flex-direction: column !important;
        gap: 30px;
    }

    .container.sitecontainer.cryptosaving {
        max-width: 100% !important;
        margin: 0 !important;
        padding: 25px 10px !important;
    }

    .contain-width {
        padding: 0 20px !important;
    }

    .data-result p {
        text-align: right;
    }
}

@media (width <=991.98px) {
    .staking.display-1 {
        flex-direction: column !important;
        gap: 20px;
    }
}

@media (width <=1199.98px) {
    .payment h2 {
        margin: 17px !important;
    }


}

@media (width <=1499.98px) {
    .construct-image img {
        opacity: 20%;
    }
}


/*Media query for min-width to max-width*/

@media (575.98px <=width <=767.98px) {}

@media (575.98px <=width <=991.98px) {}

@media (575.98px <=width <=1199.98px) {}

@media (575.98px <=width <=1299.98px) {}

@media (767.98px <=width <=991.98px) {

    .gainer-history,
    .referral-info {
        grid-template-columns: repeat(2, 1fr) !important;
    }
}

@media (767.98px <=width <=1199.98px) {}

@media (767.98px <=width <=1299.98px) {}

@media (991.98px <=width <=1199.98px) {
    .history-inputs .MuiFormControl-root {
        min-width: 284px !important;
    }
}

@media (991.98px <=width <=1299.98px) {}

@media (1199.98px <=width <=1299.98px) {}