@import "../../Scss/main.scss";

.header-logo img {
  width: 40px;
  height: 40px;
}

header,
.drawer .MuiDrawer-paper {
  background-color: var(--clr-bcg) !important;
}

.drawer .MuiDrawer-paper {
  color: var(--clr-font) !important;
}

.avatar-border {
  border: 4px solid green;
}

.profile-set {
  gap: $gap-10;
}

.profile-set svg {
  font-size: $font-25 !important;
}

.deposit-btn {
  margin: 0px $mar-10px;
  gap: $gap-10;
}

.deposit-btn button {
  color: #fff !important;
  background: $btn-primary;
}

.wallet-head {
  color: var(--clr-font);
}

a.trade-history {
  display: $flex;
  align-items: $align-c;
  gap: 8px;
  background: rgba(240, 241, 245, 1);
  padding: 9px 22px;
  border-radius: 6px;
  border: 1px solid rgba(240, 241, 245, 1);
  cursor: pointer;
  white-space: nowrap;
  position: relative;
  overflow: hidden;
  font-weight: $weight-500;
  // background: transparent;
}

body.dark-theme a.trade-history {
  background: #000;
}

a.trade-history:hover {
  background-color: #0b57d0 !important;
  color: #fff;
}

a.trade-history:hover .trade-icon svg path {
  fill: rgb(39, 170, 225);
}

.menu-bar .logo {
  margin-bottom: 0;
}

.menu-bar {
  gap: 20px;
}

.mui-list.profile-list ul.MuiMenu-list {
  padding: 10px 0;
}
.mui-list.profile-list ul.MuiMenu-list li {
  padding: 10px 30px;
}
// header menu
.drop-menu {
  position: absolute !important;
  background: var(--clr-card);
  line-height: 45px;
  top: 50px;
  opacity: 0;
  visibility: hidden;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding-left: 5px;
  width: 250px;
  padding: 10px !important;
  z-index: 1;
}

.drop-menu:hover .drop-menu {
  position: absolute !important;
  background: var(--clr-card);
  line-height: 45px;
  top: 50px;
  opacity: 0;
  visibility: hidden;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding-left: 5px;
  width: 250px;
  padding: 10px !important;
  z-index: 1;
}

.drop-down-menu-exch:hover .drop-menu {
  transition: all 0.3s ease;
  top: 25px;
  opacity: 1;
  z-index: 999;
  visibility: visible;
}

.mega-box.drop-menu {
  top: 14px !important;
}

.drop-down-menu-exch li {
  color: var(--clr-font);
}

.whole-list,
.menu-items ul li a,
.menu-items ul li {
  align-items: $center;
  display: $flex;
  color: var(--clr-font);
  cursor: pointer;
}

a {
  cursor: pointer;
}
.whole-list {
  gap: 20px;
}

.head-icon {
  display: $flex;
  align-items: $center;
  gap: 15px;
}

.menu-items ul li {
  padding: 0 8px !important;
}

body.dark-theme .head-icon svg path {
  fill: #fff;
}

.dark-mode,
body.dark-theme .light-mode {
  display: none;
}

body.dark-theme .dark-mode,
.light-mode {
  display: block;
}

.profile-img svg {
  width: 30px;
  height: 28px;
}

.mega-box {
  background: var(--clr-card);
  padding: 25px 40px !important;
  width: 850px !important;
  justify-content: space-between;
  border-radius: 10px;
  display: $flex;
  gap: 30px;
}

.content span {
  font-weight: 700;
}

.mega-ul {
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}

.logo.head a img {
  width: 105px;
  height: 35px;
}

.logo.head {
  padding: 0 20px;
  padding-left: 0;
}

.login-h-btn button {
  // font-family: Roboto;
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
  text-align: left;
  color: #ebebeb;
  background: transparent !important;
  text-transform: capitalize;
  height: 30px;
  border-radius: 6px;
}
.light-theme .login-h-btn button {
  color: #424040;
  box-shadow: none !important;
}

.reg-h-btn button {
  background: #fe7701 !important;
  // font-family: Roboto;
  font-size: 13.89px;
  font-weight: 600;
  line-height: 28px;
  text-align: center;
  color: #ffffff;
  text-transform: capitalize;
  height: 30px;
  border-radius: 6px;
}

.mui-list .MuiPaper-root {
  top: 60px !important;
}

.header-appbar {
  border-bottom: 1px solid #6a6d7861;
  position: fixed !important;
  z-index: 1200 !important;
  top: 0;
}
