@import '../../Scss/main.scss';
@import '../../Css/theme.css';

.kyc-step-number-heading.display-2 {
    gap: $gap-30;
    padding-bottom: $pad-10px ;
    border-bottom: 1px solid var(--clr-font);
}
.kyc-step-number {
    display: $flex;
    justify-content: $center;
    align-items: $center;
    color: var(--clr-font);
    font-size: 20px;
    font-weight: 600;
    border: 1px solid var(--clr-font);
    border-radius: 50%;
    padding: 13px 14px;
}
.detail-cnt {
    margin-top: $mar-20px;
}
.datepicpart div {
    width: 100%;
    padding-top: 0;
    justify-content: end;
}
.flag-image-code img {
    height: 15px;
}
.coin-img-inner.country-dtls {
    display: flex;
    align-items: center;
    gap: 5px;
}
ul.coin-list-menu.country-detailed-list {
    list-style: none;
    padding-left: 0px;
    min-height: 270px;
    max-height: 270px;
    overflow: auto;
}
.coin-img.country-img {
    display: flex;
    color: var(--clr-font);
    font-size: 12px;
    gap: 20px;
    justify-content: space-between;
}
.detail-cnt .MuiFormControl-root{
    margin: 15px 0;
}
.dealys-details ul {
    padding-left: 18px;
}
.dealys-details ul li {
 list-style: none;
 color: var(--clr-font);
 padding-bottom: $pad-10px;
}
.inner-doc {
    color: var(--clr-font);
    text-align: center;
    border-radius: 10px;
    height: 75%;
}
.uploaded-img img, .inner-doc.ticket-box img {
    width: 150px;
    clipPath: circle(50% at 50% 50%);
    border-radius: 53px;
    margin-top: 15px;
}

.detail-cnt label, .kyc-step-heading{
    color: var(--clr-font);
}
.detail-cnt label:hover{
    background: transparent !important;;
}
body.dark-theme .detail-cnt fieldset.MuiOutlinedInput-notchedOutline, body.dark-theme .address-box textarea.form-control{
    border: 1px solid #dededede;
    background: transparent;
}
.detail-cnt input,.detail-cnt textarea ,div#demo-simple-select, input#input-with-icon-textfield {
    color: var(--clr-font);
}
.datepicpart div button svg path, .detail-cnt svg  {
    fill: var(--clr-font);
}
.upload-detail{
    flex-direction: column-reverse;
}
.uplad-btn {
    margin: 25px 0;
    background: $btn-primary;
    padding: 6px 16px;
    color: #fff;
    border-radius: 6px;
}
.address-box {
    display: flex;
    flex-direction: column;
    gap: 5px;
}
.address-box textarea.form-control {
    margin: 11px 0;
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 6px;
    padding: 6px;
    color: var(--clr-font);
}
.submit-btn{
    margin-bottom: 25px;
}