@import "../../Scss/main.scss";

.wallte-div-1 {
  //     // padding: $pad-15px;
  //     // // border-radius: $radius-12px;
  //     // // background-color: var(--clr-bcg);
  //     // // box-shadow: var(--box-shadow);
  margin: $mar-50px 0px;
}
.deposit-div-1.ticket-box {
  max-width: 650px;
  margin: 30px auto;
  border-radius: 25px;
  padding: 15px;
}
.wallet-text {
  font-size: $font-20;
  font-weight: $weight-500 !important;
  // line-height: 28px;
  text-align: $left;
  color: var(--clr-font);
}

.last-update {
  font-size: $font-14;
  font-weight: $weight-400;
  line-height: 17px;
  text-align: $left;
  margin: $mar-15px 0px;
  color: var(--clr-font);
}

.last-update svg {
  fill: var(--clr-font);
  font-size: $font-20;
}

.display-2.gap {
  gap: $gap-10;
  font-size: $font-18;
  color: var(--clr-font);
}

.wallet-balance {
  font-size: $font-18;
  font-weight: $weight-600;
}

.display-2.gap svg {
  font-size: $font-20;
}

.display-2.gap.total {
  // box-shadow: var(--box-shadow);
  padding: $pad-10px;
  border-radius: $radius-12px;
}

.display-1.wallet-min2 {
  // margin-top: $mar-50px;
  flex-flow: wrap;
  justify-content: space-between;
}

.search-input input,
.search-input input::placeholder {
  color: var(--clr-font);
  padding: $pad-10px;
  border: 1px solid var(--bdr-colr1);
}

.search-input fieldset {
  border: 1px solid #ccc;
}

.Wallet button.MuiTab-root {
  color: var(--clr-font);
  min-width: 70px;
}

.nodata {
  color: var(--clr-font);
}

.Wallet button.Mui-selected {
  color: #fff !important;
  fill: #fff !important;
  background: rgba(254, 119, 1, 1);
  border-radius: 8px;
  font-weight: 500;
}

.Wallet span.MuiTabs-indicator {
    background-color: unset !important;
}

.Wallet table td {
  border-bottom: 1px solid var(--bdr-colr1);
  color: var(--clr-font);
}

.Wallet table th {
  background-color: var(--clr-bcg);
  color: var(--clr-font);
  font-size: 16px;
}

.table-logo img {
  width: $px-30;
  height: $px-30;
}

.table-logo {
  display: $flex;
  align-items: center;
  gap: 10px;
}

.dh-title {
  font-size: $font-30 !important;
  justify-content: $justi-c;
  color: var(--clr-font);
  padding-bottom: $px-30;
}

.qr-image img {
  width: 150px;
  height: 150px;
}

.qr-image {
  margin: $mar-15px 0px;
}

.disclaimer {
  font-size: $font-14;
  margin: $mar-15px;
  color: var(--clr-font);
}

.wallet-address-input fieldset {
  border: unset !important;
}

.wallet-address-input input {
  padding: $pad-10px;
  text-overflow: ellipsis;
}

.wallet-address-input {
  margin: $mar-10px 0px;
  border: 1px solid var(--clr-font);
  border-radius: 4px;
  height: 51px;
}
.deposit-history div#demo-simple-select {
  color: var(--clr-font) !important;
}
body.light-theme .MuiMenuItem-root {
  color: #000 !important;
}
.wallet-address-input .MuiFormControl-root {
  width: 100%;
}

.wallet-address-input button {
  padding: 10px 0px;
}

.back-arrow svg {
  fill: var(--clr-font);
  font-size: $font-30;
  border-radius: $radius-20px;
  box-shadow: var(--box-shadow);
}

.back-arrow {
  margin: $mar-20px;
  position: sticky;
  top: 0px;
}

.proceed-withdraw button, .proceed-withdraw button:hover {
  width: $percent-100;
  margin: $mar-20px 0px;
  background: $btn-primary;
  padding: $pad-10px;
}

.proceed-left {
  font-size: 13px !important;
  margin: 3px 0px;
  color: var(--clr-font);
}

.pagnation li button {
  color: var(--clr-font);
}

.dep-select .MuiSelect-select,
.dep-select input {
  color: var(--clr-font) !important;
}

.dep-select fieldset {
  border: 1px solid #ccc;
}

// .MuiPopover-paper {
//     background-color: var(--clr-bcg) !important;
//     color: var(--clr-font) !important;
// }

.dep-select svg {
  fill: var(--clr-font);
}

.total-ass,
.show-value p {
  margin: 0 0 15px 0;
}
.show-value p {
  font-size: $font-18;
  color: var(--clr-font);
}
.show-value.display-2.gap {
  align-items: flex-start;
}
.wallet-his .MuiBox-root {
  padding: 24px 0px;
}
.depo-with{
  gap: 10px;
}

body.light-theme .deposit-div-1.ticket-box, body.light-theme .payment .ticket-box  {
  background: rgb(231 231 231 / 19%);
  box-shadow: rgba(51, 70, 64, 0.2) 0px 7px 29px 0px !important; 
}