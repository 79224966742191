@import "..//..//Scss//main.scss";
@import "..//..//Css//theme.css";

.artist.colabrate {
  max-width: 100% !important;
}

.colab-msg-box {
  // padding: 10px; //
  //   background: var(--clr-bcg);
  border-radius: 15px;
}

.colab-text-input {
  display: flex;
  gap: 15px;
  justify-content: center;
  // background-color: var(--clr-font) !important; //
  //   background: #fff;
  padding: 5px;
  border-radius: 0px 0px 15px 15px !important;
}

.colab-send svg {
  padding: 2px 4px;
  border-radius: 12px;
  background: linear-gradient(
    274.4deg,
    #175aff 2.38%,
    #fc5aff 97.31%
  ) !important;
  font-size: 30px;
  fill: #fff;
}

.smile svg {
  background: #fc5aff80 !important;
  border-radius: 15px;
  padding: 4px;
  font-size: 30px;
}

.colab-text-input input {
  background: #ecd4ff !important;
  padding: 10px;
  border-radius: 12px;
  border: unset;
  width: 240px;
}

.colab-chat-area {
  height: 600px;
  overflow-y: scroll;
  margin: 20px 20px;
}

.colab-avatar-cont {
  padding: 15px 10px;
}

.chat-area-inner-text {
  display: flex;
  gap: 15px;
  margin: 15px 0px;
}

.chat-area-inner-text.user {
  display: flex;
  gap: 15px;
  flex-direction: row-reverse;
  margin: 15px 0px;
}

.collab-send-text-main {
  padding: 15px 10px;
  background-color: var(--support);
  border-radius: 15px;
  position: relative;
  margin-top: 10px;
  margin-right: 150px;
  color: var(--clr-font);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.collab-send-text-main2 {
  padding: 15px 10px;
  background-color: var(--support);
  border-radius: 15px;
  position: relative;
  margin-top: 10px;
  margin-left: 150px;
  color: var(--clr-font);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.collab-send-text-main:before {
  content: "";
  display: block;
  position: absolute;
  top: 0px;
  left: -5px;
  width: 17px;
  height: 19px;
  background-color: var(--support);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  // transform: translateY(-50%) rotate(332deg); //
  // transform: translateY(-50%) rotate(269deg); //
  clipPath: polygon(0 0, 100% 0, 100% 100%);
}

.collab-send-text-main2.user::after {
  content: "";
  display: block;
  position: absolute;
  top: 8px;
  right: -6px;
  width: 17px;
  height: 19px;
  background-color: var(--support);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  // transform: translateY(-50%) rotate(332deg); //
  transform: translateY(-50%) rotate(269deg);
  clipPath: polygon(0 0, 100% 0, 100% 100%);
}

.collab-send-text-main .username,
.collab-send-text-main2 .username {
  // font-family: Eloquia Text; //
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  color: var(--clr-font);
}

.collab-send-text-main .txt1,
.collab-send-text-main2 .txt1 {
  // font-family: Eloquia Text; //
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
  padding: 5px;
}

.collab-send-text-main .time,
.collab-send-text-main2 .time {
  // font-family: Eloquia Text; //
  font-size: 11px;
  font-weight: 400;
  line-height: 13px;
  letter-spacing: 0px;
  text-align: right;
  padding: 5px;
}

div#colab {
  cursor: pointer;
  display: flex;
  width: auto !important;
  //// display: contents; ////
  justify-content: flex-start !important;
  align-items: center;
  // background: transparent; //
  // max-width: none !important; //
  max-width: 80% !important;
}

.msg-list-chat-name {
  /* font-family: Roboto; */
  font-size: 16px;
  font-weight: 500;
  line-height: 18.75px;
  text-align: left;
  color: var(--clr-font);
}

.chat-desc {
  /* font-family: Roboto; */
  font-size: 12px;
  font-weight: 400;
  line-height: 15.6px;
  text-align: left;
  color: var(--input);
}

.msglist {
  background: var(--support);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 12px;
  padding: 10px 15px;
  height: 100%;
}

/* From Uiverse.io by satyamchaudharydev */
/* this is a recreation of twitter search in css */
.form {
  --input-text-color: #fff;
  --input-bg-color: (--clr-headebg);
  --focus-input-bg-color: transparent;
  --text-color: #949faa;
  --active-color: #fe7701;
  --width-of-input: 100%;
  --inline-padding-of-input: 1.2em;
  --gap: 0.9rem;
}

/* form style */
.form {
  font-size: 0.9rem;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  width: var(--width-of-input);
  position: relative;
  // isolation: isolate;
  box-shadow: 0px 2px 2px 2px #0000002e;
  border-radius: 15px;
}

/* a fancy bg for showing background and border when focus. */
.fancy-bg {
  position: absolute;
  width: 100%;
  inset: 0;
  background: var(--support);
  border-radius: 30px;
  height: 100%;
  z-index: -1;
  pointer-events: none;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

/* label styling */
.form label {
  width: 100%;
  padding: 10px;
  height: 20px;
  padding-inline: var(--inline-padding-of-input);
  display: flex;
  align-items: center;
}

.search,
.close-btn {
  position: absolute;
}

/* styling search-icon */
.search {
  fill: var(--text-color);
  left: var(--inline-padding-of-input);
}

/* svg -- size */
.form svg {
  width: 17px;
  display: block;
}

/* styling of close button */
.close-btn {
  border: none;
  right: var(--inline-padding-of-input);
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  padding: 0.1em;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: var(--active-color);
  opacity: 0;
  visibility: hidden;
}

/* styling of input */
.inputs {
  color: var(--clr-font);
  width: 100%;
  margin-inline: min(2em, calc(var(--inline-padding-of-input) + var(--gap)));
  background: none;
  border: none;
}

.inputs:focus {
  outline: none;
}

.inputs::placeholder {
  color: var(--text-color);
}

/* inputs background change in focus */
.inputs:focus ~ .fancy-bg {
  border: 1px solid var(--active-color);
  background: var(--focus-input-bg-color);
}

/* search icon color change in focus */
.inputs:focus ~ .search {
  fill: var(--active-color);
}

/* showing close button when typing */
.inputs:valid ~ .close-btn {
  opacity: 1;
  visibility: visible;
}

/* this is for the default background in input,when selecting autofill options -- you can remove this code if you do not want to override the browser style.  */
inputs:-webkit-autofill,
inputs:-webkit-autofill:hover,
inputs:-webkit-autofill:focus,
inputs:-webkit-autofill:active {
  -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
  -webkit-transition-delay: 9999s;
}

/* From Uiverse.io by vinodjangid07 */
.messageBox {
  width: 95%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--support);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 0 15px;
  border-radius: 10px;
  border: 1px solid rgb(63, 63, 63);
}

.messageBox:focus-within {
  border: 1px solid rgb(110, 110, 110);
}

.fileUploadWrapper {
  width: fit-content;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Arial, Helvetica, sans-serif;
}

#file {
  display: none;
}

.fileUploadWrapper label {
  cursor: pointer;
  width: fit-content;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.fileUploadWrapper label svg {
  height: 18px;
}

.fileUploadWrapper label svg path {
  transition: all 0.3s;
}

.fileUploadWrapper label svg circle {
  transition: all 0.3s;
}

.fileUploadWrapper label:hover svg path {
  stroke: #fff;
}

.fileUploadWrapper label:hover svg circle {
  stroke: #fff;
  fill: #3c3c3c;
}

.fileUploadWrapper label:hover .tooltip {
  display: block;
  opacity: 1;
}

.tooltip {
  position: absolute;
  top: -40px;
  display: none;
  opacity: 0;
  color: white;
  font-size: 10px;
  text-wrap: nowrap;
  background-color: #000;
  padding: 6px 10px;
  border: 1px solid #3c3c3c;
  border-radius: 5px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.596);
  transition: all 0.3s;
}

#messageInput {
  width: 100%;
  height: 100%;
  background-color: transparent;
  outline: none;
  border: none;
  padding-left: 10px;
  color: var(--clr-font);
}

#messageInput:focus ~ #sendButton svg path,
#messageInput:valid ~ #sendButton svg path {
  fill: #3c3c3c;
  stroke: white;
}

#sendButton {
  width: fit-content;
  height: 100%;
  background-color: transparent;
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s;
}

#sendButton svg {
  height: 18px;
  transition: all 0.3s;
}

#sendButton svg path {
  transition: all 0.3s;
}

#sendButton:hover svg path {
  fill: #3c3c3c;
  stroke: white;
}

.msg-lsit-head-svg svg {
  fill: #fe7701;
  font-size: 30px;
}

.bdg .MuiBadge-badge {
  background-color: #fe7701;
  right: 5px;
}

.msg-list-chat-main .MuiAvatar-circular,
.chat-area-inner-text .MuiAvatar-circular {
  border: 2px solid #fe7701;
  padding: 5px;
}

.online-bdg .MuiBadge-badge {
  background-color: #32af27;
  right: 5px;
  bottom: 8px;
}

.container {
  display: flex;
  //   height: 100vh;
  margin-top: 50px;
}

.sidebar {
  width: 360px;
  transition: width 0.3s ease;
  //   background-color: #3498db;
  margin: 2.5px;
}

.sidebar.collapsed {
  width: 70px;
}

.sidebar.collapsed .msg-list-chat-main,
.sidebar.collapsed .msg-list-head {
  white-space: nowrap;
  z-index: 0;
}

.content {
  flex-grow: 1;
  //   background-color: #ecf0f1;
  z-index: 2;
  margin: 2.5px;
}
