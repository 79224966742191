@import "../../Scss/main.scss";

.Tradepage button.MuiTab-root {
  color: var(--clr-font);
  min-width: 70px;
}

.Tradepage button.Mui-selected,
.Tradepage button.Mui-selected svg {
  color: rgba(39, 170, 225, 1) !important;
  // fill: rgba(39, 170, 225, 1) !important;
  // border: 1px solid rgba(39, 170, 225, 1) !important;
}

.trade-main {
  padding: $pad-10px $pad-20px;
  color: var(--clr-font);
  border: 1px solid #cccccc6b;
}

.Tradepage span.MuiTabs-indicator {
  background-color: unset !important;
}

.trade-main-tab .MuiTabs-flexContainer {
  justify-content: $justi-sb;
}

.trade-left-table th {
  border-bottom: unset;
  padding: 3px;
}

.trade-left-table,
.trade-right-table {
  height: 260px;
  overflow-y: scroll;
}

.trade-left-table.tb2 {
  height: 630px;
}

.trade-left-table th,
.trade-right-table th {
  border-bottom: unset;
  padding: 2px;
  background-color: var(--clr-bcg);
  color: var(--clr-font);
}

.trade-left-table.model th,
.trade-left-table.model td {
  background-color: var(--model);
}

.light-theme .trade-left-table.model th,
.light-theme .trade-left-table.model td {
  background-color: #fff;
}

.light-theme .modal-trade {
  background-color: #fff;
}

.light-theme .modal-trade .input {
  background-color: #fff;
}

div#tradingview_chart div {
  width: 100% !important;
  height: 570px !important;
}

.display-2 svg {
  font-size: 18px;
}

.spot-tab .MuiBox-root {
  padding: 0px !important;
}

.inputbox-div .MuiFormControl-root {
  width: 88%;
  // padding: 10px;
  margin: 5px 0px;
}

.inputbox-div {
  text-align: $center;
}

.sell-div button {
  background-color: red !important;
  padding: $px-10;
  color: #fff;
  width: 90%;
}

.buy-div button {
  background-color: green !important;
  padding: $px-10;
  color: #fff;
  width: 90%;
}

.sell-div,
.buy-div {
  text-align: $center;
  margin: $pad-10px;
}

.nodata {
  display: $flex;
  align-items: $center;
  flex-direction: $flex-column;
  gap: $gap-15;
}

.display-sticky {
  position: sticky;
  bottom: 0;
  z-index: 999;
}

.sticy {
  // margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  background-color: var(--clr-bcg);
  padding: 0px $px-20;
}

.sticy .sell-div,
.sticy .buy-div {
  width: 100%;
}

.close-icon {
  padding: $pad-10px;
  text-align: $right;
}

.close-icon svg path {
  fill: var(--brbtm);
}

.inputbox-div fieldset {
  border: 1px solid #ccc;
}

.inputbox-div label,
.inputbox-div input {
  color: var(--clr-font);
}

.Tradepage th {
  background-color: var(--clr-bcg);
  color: var(--clr-font);
  font-size: 12px;
  white-space: nowrap;
}

/* From Uiverse.io by cbolson */
.switch {
  --_switch-bg-clr: #f3f3f325;
  --_switch-padding: 4px;
  /* padding around button*/
  --_slider-bg-clr: #121212;
  /* slider color unchecked */
  --_slider-bg-clr-on: #121212;
  /* slider color checked */
  --_slider-txt-clr: #ffffff;
  --_label-padding: 1rem 2rem;
  /* padding around the labels -  this gives the switch it's global width and height */
  --_switch-easing: cubic-bezier(0.47, 1.64, 0.41, 0.8);
  /* easing on toggle switch */
  color: white;
  width: fit-content;
  display: flex;
  justify-content: center;
  position: relative;
  border-radius: 9999px;
  cursor: pointer;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  position: relative;
  isolation: isolate;
  height: 40px;
  margin-left: 10px;
}

.switch input[type="checkbox"] {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.switch>span {
  display: grid;
  place-content: center;
  transition: opacity 300ms ease-in-out 150ms;
  padding: 12px 16px;
}

.switch::before,
.switch::after {
  content: "";
  position: absolute;
  border-radius: inherit;
  transition: inset 150ms ease-in-out;
}

/* switch slider */
.switch::before {
  background-color: var(--_slider-bg-clr);
  inset: var(--_switch-padding) 50% var(--_switch-padding) var(--_switch-padding);
  transition: inset 500ms var(--_switch-easing),
    background-color 500ms ease-in-out;
  z-index: -1;
  //   box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.3), 0 1px rgba(255, 255, 255, 0.3);
}

/* switch bg color */
.switch::after {
  background-color: var(--_switch-bg-clr);
  inset: 0;
  z-index: -2;
}

/* switch hover & focus */
.switch:focus-within::after {
  inset: -0.25rem;
}

.switch:has(input:checked):hover>span:first-of-type,
.switch:has(input:not(:checked)):hover>span:last-of-type {
  opacity: 1;
  transition-delay: 0ms;
  transition-duration: 100ms;
}

/* switch hover */
.switch:has(input:checked):hover::before {
  inset: var(--_switch-padding) var(--_switch-padding) var(--_switch-padding) 45%;
}

.switch:has(input:not(:checked)):hover::before {
  inset: var(--_switch-padding) 45% var(--_switch-padding) var(--_switch-padding);
}

/* checked - move slider to right */
.switch:has(input:checked)::before {
  background-color: var(--_slider-bg-clr-on);
  inset: var(--_switch-padding) var(--_switch-padding) var(--_switch-padding) 50%;
}

/* checked - set opacity */
.switch>span:last-of-type,
.switch>input:checked+span:first-of-type {
  opacity: 0.75;
}

.switch>input:checked~span:last-of-type {
  opacity: 1;
}

.hoz-des {
  width: 2.5px;
  height: 15px;
  background: #f3f3f37c;
}

.select-form {
  //   font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  //   line-height: 18.75px;
  text-align: left;
  color: var(--clr-font) !important;
  border: none !important;
  display: flex;
  align-items: center;
  gap: 10px;
  padding-right: 0px;
  margin-bottom: 10px;
}

.select-form:focus-visible {
  outline: 0 !important;
}

.select-form fieldset {
  border: none !important;
}

.Btcoin {
  width: 20px;
  height: 20px;
}

.total-data {
  // font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  line-height: 18.2px;
  text-align: left;
  color: #f66754;
}

.total-sub,
.total-sub span {
  // font-family: Roboto;
  font-size: 11px;
  font-weight: 400;
  line-height: 15.6px;
  text-align: left;
  color: #f66754;
  white-space: nowrap;
}

// .total-sub span {
//   margin: 0 5px;
// }

.lh-24 {
  // font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 15.6px;
  text-align: left;
  color: var(--clr-font);
  white-space: nowrap;
}

.lh-sub {
  // font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  line-height: 15.6px;
  text-align: left;
  color: var(--clr-font);
}

.tabs-trade button.MuiButtonBase-root {
  // font-family: Roboto;
  font-size: 13px;
  font-weight: 400;
  line-height: 15.23px;
  text-align: left;
  text-transform: capitalize;
  color: var(--clr-font) !important;
  // border: 1px solid #ccc;
  // color: #ff7028 !important;
  border-radius: 5px;
  height: 10px;
}

.tabs-trade button.MuiButtonBase-root.Mui-selected {
  // font-family: Roboto;
  font-size: 13px;
  font-weight: 400;
  line-height: 15.23px;
  text-align: left;
  text-transform: capitalize;
  border: 1px solid;
  color: #ff7028 !important;
  border-radius: 5px;
  height: 10px;
}

.tabs-trade,
.buy-sell-tabs {
  min-height: 25px !important;
}

.buy-sell-tabs button.buy.MuiButtonBase-root.Mui-selected {
  // font-family: Roboto;
  font-size: 13px;
  font-weight: 500;
  line-height: 30px;
  text-align: center;
  color: #1d1d1d !important;
  background: #25d17f;
  text-transform: capitalize;
  border-radius: 25px;
  min-width: 130px;
  padding: 4px 16px;
  min-height: 38px;
}

.buy-sell-tabs button.sell.MuiButtonBase-root.Mui-selected {
  // font-family: Roboto;
  font-size: 13px;
  font-weight: 500;
  line-height: 30px;
  text-align: center;
  color: #1d1d1d !important;
  background: #f66754;
  text-transform: capitalize;
  border-radius: 25px;
  min-width: 130px;
  padding: 4px 16px;
  min-height: 38px;
}

.buy-sell-tabs button.MuiButtonBase-root {
  color: var(--grey);
  font-size: 13px;
  font-weight: 500;
  line-height: 30px;
  text-align: center;
  text-transform: capitalize;
  border-radius: 25px;
  min-width: 130px;
  padding: 4px 16px;
  min-height: 38px;
}

.buy-sell-tabs .MuiTabs-flexContainer {
  background: var(--clr-grey);
  width: fit-content;
  border-radius: 25px;
  height: 35px;
}

.aval {
  // font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  line-height: 14.06px;
  text-align: left;
  color: var(--clr-font);
}

.usd {
  // font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  line-height: 14.06px;
  color: var(--clr-font);
}

.price-field {
  background: var(--clr-grey);
  border-radius: 8px;
  margin-top: 10px !important;
}

.price-field input {
  padding: 12px 8px;
  // font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.41px;
  text-align: left;
  color: var(--clr-font) !important;
  // opacity: 60% !important;
}

.price-field input::placeholder {
  padding: 12px 8px;
  // font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.41px;
  text-align: left;
  color: var(--clr-font) !important;
  opacity: 60% !important;
}

.Mui-disabled {
  -webkit-text-fill-color: inherit !important;
}

.adroment p {
  // font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.41px;
  text-align: left;

  color: var(--input);
}

.price-field fieldset {
  border: 0;
}

/* From Uiverse.io by Pradeepsaranbishnoi */
:focus {
  outline: 0;
  // border-color: #2260ff;
  // box-shadow: 0 0 0 4px #b5c9fc;
}

.mydict div {
  display: flex;
  flex-wrap: wrap;
  margin-top: 0.9rem;
  justify-content: space-between;
}

.mydict2.x2 div {
  display: flex;
  flex-wrap: wrap;
  margin-top: -0.1rem;
  justify-content: flex-start;
  gap: 3px;
}

.mydict input[type="radio"] {
  clip: rect(0 0 0 0);
  clipPath: inset(100%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.mydict input[type="radio"]:checked+span {
  // box-shadow: 0 0 0 0.0625em #fff;
  background-color: #ff7028;
  z-index: 1;
  color: var(--clr-font);
}

.mydict label span {
  display: block;
  cursor: pointer;
  background-color: var(--clr-grey);
  // padding: 0.375em 0.75em;
  position: relative;
  margin-left: 0.0625em;
  font-size: 12px;
  font-weight: 400;
  line-height: 15.6px;
  text-align: center;
  color: var(--clr-font);
  padding: 5px 20px;
  letter-spacing: 0.05em;
  // color: #3e4963;
  text-align: center;
  transition: background-color 0.5s ease;
}

.mydict label span {
  border-radius: 0.375em;
}

.def-tab.active {
  // box-shadow: 0 0 0 0.0625em #fff;
  background-color: #ff7028;
  // z-index: 1;
  color: var(--clr-font);
}

.def-tab {
  display: block;
  cursor: pointer;
  background-color: var(--clr-grey);
  // padding: 0.375em 0.75em;
  position: relative;
  margin-left: 0.0625em;
  font-size: 12px;
  font-weight: 400;
  line-height: 15.6px;
  text-align: center;
  color: var(--input);
  padding: 6px 20px;
  letter-spacing: 0.05em;
  // color: #3e4963;
  text-align: center;
  border-radius: 4px;
  transition: background-color 0.5s ease;
}

.mydict2 label span {
  border-radius: 0.375em;
}

.buy-btn button {
  background: #25d17f !important;
  // font-family: Roboto;
  font-size: 12px;
  font-weight: 700;
  line-height: 16.41px;
  text-align: center;
  border-radius: 25px;
  width: 100%;
  padding: 10px 10px;
  text-transform: capitalize;
  color: var(--clr-font);
}

.sell-btn button {
  background: #f66754 !important;
  // font-family: Roboto;
  font-size: 12px;
  font-weight: 700;
  line-height: 16.41px;
  text-align: center;
  text-transform: capitalize;
  border-radius: 25px;
  width: 100%;
  padding: 10px 10px;
  color: var(--clr-font);
}

.trade-left-table th,
.trade-left-table td {
  // font-family: Roboto;
  font-size: 10px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  margin: 1px;
  color: var(--grey) !important;
}

.bg-red {
  background-color: rgba(139, 25, 25, 0.267) !important;
  // margin: 1px;
}

.bg-green {
  background-color: rgba(38, 139, 25, 0.267) !important;
  // margin: 1px;
}

.dep-trns-tbn button {
  // font-family: Roboto;
  font-size: 12px;
  font-weight: 700;
  line-height: 14.06px;
  text-align: center;
  color: var(--clr-font);
  border-radius: 15px;
  background: var(--clr-btn) !important;
  text-transform: capitalize;
  padding: 6px 20px;
}

.Tradepage .MuiBox-root,
.modal-trade .MuiBox-root {
  padding: 0% !important;
}

.Tradepage th,
.Tradepage td {
  border: 0px !important;
  // padding: 8px 16px;
}

.trade-hist th,
.trade-hist td {
  // border: 0px !important;
  padding: 8px 16px;
}

.Tradepage .MuiButtonBase-root,
.modal-trade .MuiButtonBase-root {
  min-height: 30px !important;
}

.orderBook .MuiButtonBase-root.MuiTab-root,
.modal-trade .MuiButtonBase-root {
  padding: 6px 6px !important;
  min-width: 35px !important;
}

.tr-green,
.tr-green {
  color: #01bc8d !important;
}

.tr-green th,
.tr-green td {
  color: #01bc8d !important;
}

.tr-red th,
.tr-red td {
  color: #f66754 !important;
}

.tr-red,
.tr-red {
  color: #f66754 !important;
}

.drawer-bottom .MuiDrawer-paper {
  background-color: var(--clr-bcg) !important;
}

.tabs-trade .MuiTabs-indicator,
.buy-sell-tabs .MuiTabs-indicator {
  display: none !important;
}

.mob .MuiTabs-flexContainer {
  justify-content: space-between;
}

/* From Uiverse.io by LightAndy1 */
.group {
  display: flex;
  line-height: 28px;
  align-items: center;
  position: relative;
  max-width: 100%;
}

.input {
  font-family: "Montserrat", sans-serif;
  width: 100%;
  height: 45px;
  padding-left: 2.5rem;
  box-shadow: 0 0 0 1.5px #2b2c37, 0 0 25px -17px #000;
  border: 0;
  border-radius: 12px;
  background-color: var(--model);
  outline: none;
  color: #bdbecb;
  transition: all 0.25s cubic-bezier(0.19, 1, 0.22, 1);
  cursor: text;
  z-index: 0;
}

.input::placeholder {
  color: #bdbecb;
}

.input:hover {
  box-shadow: 0 0 0 2.5px #2f303d, 0px 0px 25px -15px #000;
}

.input:active {
  transform: scale(0.95);
}

.input:focus {
  box-shadow: 0 0 0 2.5px #2f303d;
}

.search-icon {
  position: absolute;
  left: 1rem;
  fill: #bdbecb;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  z-index: 1;
}

.buy-h-btn button {
  background: #25d17f !important;
  // font-family: Roboto;
  font-size: 14px;
  font-weight: 700;
  line-height: 16.41px;
  text-align: center;
  text-transform: capitalize;
  width: 90%;
  padding: 12px;
  margin: auto;
}

.sell-h-btn button {
  background: #f66754 !important;
  // font-family: Roboto;
  font-size: 14px;
  font-weight: 700;
  line-height: 16.41px;
  text-align: center;
  text-transform: capitalize;
  width: 90%;
  padding: 12px;
  margin: auto;
}

/* From Uiverse.io by fanishah */
.loading-bar {
  position: relative;
  width: 110px;
  height: 110px;
  background: transparent;
  border: px solid #3c3c3c;
  border-radius: 50%;
  text-align: center;
  line-height: 111px;
  font-family: sans-serif;
  font-size: 15px;
  color: #f66754;
  letter-spacing: 3px;
  text-transform: uppercase;
  text-shadow: 0 0 20px #f66754;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
}

.loading-bar:before {
  content: "";
  position: absolute;
  top: -3px;
  left: -3px;
  width: 100%;
  height: 100%;
  border: 3px solid transparent;
  border-top: 5px solid #f66754;
  border-right: 5px solid #f66754;
  border-radius: 50%;
  animation: animateC 2s linear infinite;
}

@keyframes animateC {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate {
  0% {
    transform: rotate(45deg);
  }

  100% {
    transform: rotate(405deg);
  }
}

.ft-btn button {
  color: var(--clr-font);
  text-transform: capitalize;
  background-color: var(--clr-grey) !important;
  margin-right: 5px;
  font-size: 11px;
  white-space: nowrap;
  padding: 0px 7px;
  height: 10px;
}

.ft-btn.active button {
  background-color: #373636 !important;
}

.cross div,
.x50 div {
  height: 16px;
  font-size: 11px;
  padding: 5px 5px;
  border-radius: 5px;
  color: var(--clr-font);
  text-align: center;
  text-transform: capitalize;
  background-color: var(--clr-grey) !important;
  margin: 0;
  width: 55px;
  cursor: pointer;
}

body.light-theme .cross div,
body.light-theme .x50 div {
  background-color: #cccccc !important;
}

/* From Uiverse.io by Xtenso */
.filter-switch {
  border: 2px solid #fe7701;
  border-radius: 30px;
  position: relative;
  display: flex;
  align-items: center;
  height: 50px;
  width: 100%;
  overflow: hidden;
}

.filter-switch input {
  display: none;
}

.filter-switch label {
  flex: 1;
  text-align: center;
  cursor: pointer;
  border: none;
  border-radius: 30px;
  position: relative;
  overflow: hidden;
  z-index: 1;
  transition: all 0.5s;
  font-weight: 500;
  font-size: 15px;
}

.filter-switch .background {
  position: absolute;
  width: 49%;
  height: 42px;
  background-color: #fe7701;
  top: 4px;
  left: 4px;
  border-radius: 30px;
  transition: left 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

#option2:checked~.background {
  left: 50%;
}

#option1:checked+label[for="option1"] {
  color: var(--clr-font);
  font-weight: bold;
}

#option2:checked+label[for="option2"] {
  color: var(--clr-font);
  font-weight: bold;
}

#option1:not(:checked)+label[for="option1"],
#option2:not(:checked)+label[for="option2"] {
  color: var(--clr-font);
}

.font-clr {
  color: var(--clr-font);
}

.cnfi button {
  text-transform: capitalize;
  color: var(--clr-font);
  padding: 15px;
  width: 100%;
  height: 45px;
  background: #fe7701 !important;
  border-radius: 6px;
}

.sld .MuiSlider-root {
  color: rgb(255, 112, 40) !important;
}

.sld .MuiSlider-thumb {
  clipPath: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
}

.al-right input,
.al-right input::placeholder {
  text-align: right;
}

.w2 {
  width: 50%;
}

.cncel button {
  font-size: 13px;
  font-weight: 400;
  line-height: 15.23px;
  text-align: left;
  text-transform: capitalize;
  border: 1px solid;
  color: #ff7028 !important;
  border-radius: 5px;
  height: 10px;
}

.reUp .MuiButtonBase-root.Mui-selected {
  color: var(--clr-font) !important;
  text-transform: capitalize;
  font-size: 12px;
  padding: 5px;
  border-bottom: 1px solid #ff7028 !important;
}

.reUp .MuiButtonBase-root {
  color: var(--clr-font) !important;
  text-transform: capitalize;
  font-size: 12px;
  padding: 5px;
  // border-bottom: 1px solid #ff7028 !important;
}

.reUp {
  min-height: 30px !important;
}

.reUp .MuiTabs-indicator {
  display: none;
}

.mgrid {
  border: 1px solid #6a6d7838;
  // border-radius: 5px;
  padding: 10px;
}

.transfer-btn {
  // display: block;
  cursor: pointer;
  background-color: var(--clr-grey);
  text-transform: capitalize !important;
  font-size: 12px !important;
  font-weight: 400;
  // line-height: 15.6px;
  color: var(--clr-font);
  padding: 8px 12px;
  // height: 25px;
  border-radius: 5px;
}

.box-cls .MuiTabs-root {
  min-height: 30px !important;
}

.mydict label{
  margin-top: 10px;
}