@import "../../Scss/main.scss";

// button {
//     background-color: var(--clr-bcg) !important;
// }

.darkmode button img {
  width: 21px;
  height: 21px;
}

.darkmode button {
  min-width: 0 !important;
  padding: 6px 0;
}
