@import "../../Scss/main.scss";
@import "../../Css/theme.css";

body.dark-theme .dark {
  display: block;
}
body.light-theme .dark {
  display: none;
}
body.dark-theme .light {
  display: none;
}
body.light-theme .light {
  display: block;
}
.h_2 {
  font-weight: 700;
  font-size: 40px;
  line-height: 1.2;
  color: var(--clr-font);
}

.input_grp {
  border: 1px solid var(--grey);
  display: inline-flex;
  border-radius: 8px;
}
.input_grp .input_txt {
  padding: 10px;
  padding-left: 13px;
  padding-right: 13px;
}
.input_grp .input_txt.left {
  padding-right: 10px;
}
.input_grp .input_txt.right input {
  padding-right: 5px;
}
.input_grp input {
  background: transparent !important;
  border: 0 !important;
  box-shadow: none !important;
  outline: 0 !important;
  color: var(--clr-font);
  padding: 5px;
}
.input_grp input::placeholder {
  color: var(--grey);
  font-weight: 500;
  font-size: 14px;
}
.img_box img {
  width: 404px;
  height: 400px;
}
section.build .img_box img {
  width: 150px;
}
.img_box.portfolio img {
  width: 185px !important;
}
.banner {
  background-size: cover;
  padding: 10px 0 150px;
  margin-top: -14px;
}
body.dark-theme .banner {
  background: url(../../Img/banbg.png) no-repeat center #000;
}
body.light-theme .banner {
  background: url(../../Img/banbg-light.png) no-repeat center;
}
.banner h1 {
  font-weight: 700;
  font-size: 68px;
  line-height: 109%;
  color: var(--clr-font);
  margin-bottom: 30px;
}
.banner p {
  font-weight: 600;
  margin-bottom: 30px;
  font-size: 20px;
  // line-height: 110%;
  color: var(--clr-font);
}
.banner .input_grp {
  background: var(--clr-bk);
  border: 1px solid var(--bdr-colr);
  width: 51%;
}
.banner .input_grp a {
  background: #fe7701;
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  color: #fff;
  border-radius: 5px;
  padding: 11px 20px;
  display: inline-block;
}
.banner .input_txt {
  padding-top: 0;
  padding-bottom: 0;
  padding-right: 0;
  display: flex;
  width: 100%;
}
.banner .input_txt input {
  flex: 1;
}
body.light-theme .banner .input_grp {
  background: #effbff;
  border: 1px solid #fe7701;
}

.ban_blw {
  padding: 50px 0 70px;
  overflow: hidden;
}
.ban_blw .box {
  background: #1b1b1b;
  border: 1px solid #292929;
  border-radius: 6px;
  padding: 1px 15px;
  display: flex;
  align-items: center;
}
.ban_blw .box span {
  font-weight: 400;
  font-size: 11px;
  line-height: 186%;
  color: #56595e;
}
.ban_blw .box h5 {
  font-weight: 400;
  font-size: 15px;
  line-height: 141%;
  color: #ebebeb;
  margin: 0;
  margin-bottom: 10px;
}
.ban_blw .box a {
  font-weight: 600;
  font-size: 11px;
  line-height: 103%;
  display: flex;
  // align-items: center;
  color: #fe7701;
  gap: 2px;
}
.ban_blw .box > div {
  width: 50%;
}
.ban_blw .box > div:nth-child(2) {
  text-align: center;
}
.ban_blw .slide {
  flex-wrap: nowrap;
  animation: slide 15s linear infinite;
  min-width: 1436px;
  width: 1436px;
}
.ban_blw .contain-width:hover .slide {
  animation-play-state: paused;
}
@keyframes slide {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}
.ban_blw .slide > div {
  width: 350px;
  min-width: 350px;
  height: 100%;
}
.ban_blw .contain-width {
  display: flex;
  justify-content: flex-start;
  gap: 58px;
  overflow: hidden;
}
.ban_blw_chg {
  background: #151515;
}
.ban_blw_chg .slide {
  display: flex;
  align-items: center;
  min-width: 200px;
  width: 1475px;
  min-width: 1475px;
  gap: 20px;
}
.ban_blw_chg .contain-width {
  gap: 224px;
}
.ban_blw_chg .slide {
  animation: slide 25s linear infinite;
}
.home_market {
  margin-bottom: 70px;
}
.home_market .market-current-history .tab_bdr {
  border-bottom: none !important;
  margin-bottom: -10px !important;
}
.home_market .market-table th {
  background-color: transparent;
}
.home_market .market-current-history .css-1aquho2-MuiTabs-indicator {
  background-color: #ffffff00;
}
.home_market .grey {
  color: var(--grey);
}
.home_market .market-table .actions a {
  width: 88px;
  text-align: center;
  padding: 10px;
  color: var(--clr-font) !important;
  background: rgba(255, 255, 255, 0.08) !important;
  border-radius: 8px;
}
.home_market .market-table .actions a:hover {
  background: #ff7028 !important;
}
.home_market .view_more {
  background: #ff7028;
  font-weight: 700;
  font-size: 17px;
  line-height: 118%;
  letter-spacing: 0.01em;
  text-align: center;
  color: #fff;
  display: block;
  border-radius: 100px;
  width: 144px;
  margin: 20px auto;
  padding: 16px;
}
body.dark-theme .home_market .market-table .actions a {
  background: rgba(255, 255, 255, 0.08);
}

.home_secs {
  padding: 30px 0;
  max-width: 1288px;
  margin: 0 auto;
}
.home_secs .img_box {
  width: 100%;
  text-align: center;
}
.home_secs h2 {
  margin-bottom: 20px;
  color: rgba(255, 255, 255, 0.8);
  line-height: 100%;
}
.home_secs p {
  color: var(--grey);
}
body.light-theme h2 {
  color: rgba(0, 0, 0, 0.8);
}
body.dark-theme .support-head h1 {
  color: #fff;
}

.your_trusted {
  padding: 56px 0;
}
.your_trusted h2 {
  font-size: 48px;
  margin: 0;
  margin-bottom: 10px;
}
.your_trusted .s_h {
  font-weight: 400;
  font-size: 19px;
  line-height: 122%;
  letter-spacing: -0.02em;
  color: var(--grey);
  margin-bottom: 35px;
}
.your_trusted .box {
  background: var(--clr-bk);
  border: 1px solid var(--bdr-colr);
  padding: 15px;
  border-radius: 8px;
  width: 100%;
}
.your_trusted .img_box {
  text-align: center;
}
.your_trusted .img_box img {
  max-width: 100%;
  height: auto;
}

.your_trusted .box h5 {
  color: var(--clr-font);
  font-weight: 700;
  font-size: 17px;
  line-height: 134%;
  margin-bottom: 12px;
}
.your_trusted .box p {
  font-weight: 400;
  font-size: 14px;
  line-height: 152%;
  color: var(--grey);
  margin-bottom: 0;
}
body.light-theme .your_trusted .box {
  background: #f5f5f5;
}

.build {
  padding: 56px 0;
}
.build .whole_box {
  max-width: 90%;
  margin: 0 auto;
}
.build h2 {
  font-size: 45px;
  margin: 0;
  text-align: center;
  margin-bottom: 35px;
}
.build .box {
  background: var(--clr-bk);
  border: 1px solid var(--bdr-colr);
  padding: 15px;
  border-radius: 8px;
  text-align: center;
  width: 100%;
}
.build .box a {
  color: #ff8200 !important;
  text-decoration: none !important;
}
.build .img_box {
  text-align: center;
  margin-top: 30px;
}
.build .img_box img {
  max-width: 100%;
  height: auto;
}

.build .box h5 {
  color: var(--clr-font);
  font-weight: 700;
  font-size: 22px;
  line-height: 185%;
  margin: 0;
  margin-bottom: 12px;
}
.build .box p {
  font-weight: 400;
  font-size: 15px;
  line-height: 152%;
  color: var(--clr-font);
  margin: 0;
  margin-bottom: 10px;
}
body.light-theme .build .box {
  background: #f5f5f5;
}

.why_choose .flx {
  display: flex;
  gap: 24px;
}
.why_choose .flx > div {
  background: #222;
  border-radius: 24px;
  padding: 20px;
  width: 250px;
  transition: 0.5s;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  transition: 0.5s;
  height: 330px;
  // width: 250px;
}
.why_choose .flx > div.hover:hover {
  transition: 0.5s;
  width: 300px;
  height: 330px !important;
}
.why_choose img {
  max-width: 100%;
  height: auto;
  margin-top: auto;
}
.why_choose .flx h6 {
  font-weight: 500;
  font-size: 32px;
  line-height: 140%;
  color: var(--clr-font);
  margin-bottom: 20px;
  margin-top: 0;
  // min-height: 81px;
}
.why_choose .flx p {
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: var(--clr-font);
  display: none;
}
.why_choose .flx > div.hover p {
  display: block !important;
}
.why_choose .flx img {
  max-width: 100%;
  height: auto;
}
body.light-theme .why_choose .flx > div {
  background: #fafafa;
  box-shadow: 1px 3px 3px #d1d1d1a1;
}

.copy_trading {
  padding-top: 100px;
}
.copy_trading .copy {
  font-weight: 700;
  font-size: 24px;
  line-height: 140%;
  margin-bottom: 10px;
  color: var(--clr-font);
}
.copy_trading h2 {
  font-weight: 500;
  font-size: 56px;
  margin: 0;
  margin-bottom: 30px;
}
.copy_trading span {
  font-weight: 400;
  font-size: 20px;
  line-height: 140%;
  color: var(--grey);
}
.copy_trading .start {
  border-radius: 18px;
  background: #fff;
  font-weight: 500;
  font-size: 18px;
  // line-height: 356%;
  color: #ff7028;
  padding: 0 20px;
  display: flex;
  align-items: center;
  gap: 20px;
  width: fit-content;
  margin-left: auto;
  height: 55px;
  transition: color 0.5s ease-in-out, box-shadow 0.5s ease-in-out;
}
.copy_trading .start:hover {
  box-shadow: inset 500px 0 0 0 #ff7028;
  color: #fff;
}
.copy_trading .start:hover svg path {
  fill: #fff;
}
.copy_trading .flx_box {
  border-top: 1px solid var(--bdr-colr1);
  border-bottom: 1px solid var(--bdr-colr1);
  padding: 20px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 40px;
  flex-wrap: wrap;
}
.copy_trading .flx_box_chg {
  border: 0;
  padding: 0;
  margin: 0;
}

.copy_trading .flx_box h5 {
  font-weight: 700;
  font-size: 32px;
  line-height: 140%;
  color: var(--clr-font);
  margin: 0;
  margin-bottom: 10px;
}
.copy_trading .flx_box .sub_n {
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #d1d1d2;
}
body.light-theme .copy_trading .flx_box h5 {
  color: #ff7028;
}

body.light-theme .copy_trading .flx_box .sub_n {
  color: #000;
}
body.light-theme .copy_trading .start {
  background: linear-gradient(
    180deg,
    rgba(214, 168, 145, 0.2) 0%,
    rgba(255, 112, 40, 0.2) 100%
  );
}

.trade_any {
  padding: 70px 0 0;
  margin-bottom: -4px;
}
.trade_any .whole_box {
  max-width: 90%;
  margin: 0 auto;
}
.trade_any h2 {
  margin: 0;
}
.trade_any h2 + p {
  font-weight: 400;
  font-size: 18px;
  line-height: 140%;
  color: var(--grey);
  margin-bottom: 40px;
}
.trade_any .qr_b {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  align-items: center;
  color: #d1d1d2;
}
.trade_any .qr_b .scan {
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
}
.trade_any .qr_b h5 {
  margin: 0;
  font-weight: 500;
  font-size: 28px;
  line-height: 140%;
}
.trade_any .r_f {
  display: flex;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
  margin-top: 30px;
}
.trade_any .r_f > div {
  border: 1px solid #2e2e2e;
  border-radius: 18px;
  display: flex;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
  color: #d1d1d2;
  padding: 10px 20px;
  height: 43px;
}

body.light-theme .trade_any .qr_b,
body.light-theme .trade_any .r_f > div {
  color: #000;
}

.trade_now {
  text-align: center;
  background: url("../../Img/tradenow_bg.png") no-repeat center;
  padding: 50px 0;
  overflow: hidden;
}
.trade_now h2 {
  margin: 0;
  margin-bottom: 10px;
}

.trade_now p {
  margin: 0;
  margin-bottom: 30px;
  font-weight: 400;
  font-size: 20px;
  line-height: 130%;
  color: #d1d1d2;
}

.trade_now .start {
  border-radius: 18px;
  background: #ff7028;
  font-weight: 500;
  font-size: 18px;
  width: 168px;
  color: #fff;
  padding: 0 22px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  margin: 0 auto;
  transition: color 0.5s ease-in-out, box-shadow 0.5s ease-in-out;
  height: 55px;
}
.trade_now .start:hover {
  box-shadow: inset 500px 0 0 0 #ff7028;
}


body.light-theme .trade_now {
  background: url("../../Img/tradenowbg_white.png") no-repeat center;
}
body.light-theme .trade_now p {
  color: #000;
}

// .bot_presents_box {
//     border-radius: 4px;
//     box-shadow: 0 .1px .3px rgba(0, 0, 0, .1), 0 1px 2px rgba(0, 0, 0, .2);
//     padding: 12px;
//     background-color: rgb(248, 248, 248);
//     overflow: hidden;
// }
// .bot_presents_box .head {
//     display: flex;
//     align-items: center;
// }
// .bot_presents_box .chart_flx {
//     display: flex;
//     align-items: center;
// }
// .bot_presents_box .chart_flx span {
//     text-overflow: ellipsis;
//     overflow: hidden;
//     white-space: nowrap;
//     font-weight: 700;
//     font-size: 12px;
//     color: #6b7b8b;
// }
// .bot_presents_box .chart_flx p.green {
//     color: rgb(0, 165, 154);
// }
// .bot_presents_box .coins {
//     display: flex;
//     gap: 16px;
//     align-items: center;
// }
// .bot_presents_box .coins span {
//     font-size: 12px;
//     line-height: 16px;
//     color: #6f7478;
//     font-weight: 700;
// }
// .bot_presents_box .coins_list {
//     display: flex;
// }
// .bot_presents_box .coins p {
//     font-size: 10px;
//     line-height: 16px;
//     color: #6f7478;
//     font-weight: 600;
// }
// .bot_presents_box .Copy_flex {
//     display: flex;
//     align-items: center;
//     color: #2f4050;
//     font-size: 12px;
//     gap: 8px;
// }
// .bot_presents_box .connect_btn_grn {
//     font-size: 12px;
//     width: auto;
//     padding: 6px 24px;
//     border-radius: 4px;
//     background-color: rgb(11, 142, 135);
// }
// .bot_presents_box .Copy_flex > div {
//     display: flex;
//     align-items: center;
//     gap: 7px;
// }
// .bot_presents_box .Copy_flex img {
//     height: 20px;
// }

.card_box {
  margin-top: 70px;
}
.card_box .box {
  background: var(--clr-bg);
  border: 2px solid var(--bdr-colr1);
  padding: 20px;
  border-radius: 10px;
  color: var(--clr-font);
}
.card_box .box:hover {
  transform: translateY(-12px);
  transition: all 0.3s ease;
}
body:not(.light-theme) .card_box .box:hover {
  box-shadow: 0 22px 38px 0 rgba(0, 0, 0, 0.5);
  background: linear-gradient(180deg, #222 0%, #352c23 100%);
  border: 2px solid #fe7701 !important;
}
body:not(.dark-theme) .card_box .box:hover {
  border: 2px solid #fe7701 !important;
  transition: 0.5s;
}
.card_box .box:hover .copy_btn {
  transition: 0.5s;
  background: #ff7028;
}
.card_box .box .h_flx {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
}
.card_box .box .h_flx > div:nth-child(2) div {
  display: flex;
  align-items: center;
}
.card_box .box .h_flx > div:nth-child(2) div .grey {
  color: var(--grey);
}
.card_box .box .h_flx svg {
  margin: 5px;
}
.card_box .box .h_flx h5 {
  font-weight: 400;
  font-size: 20px;
  line-height: 140%;
  margin: 0;
  margin-bottom: 10px;
}
.card_box .box .h_flx span {
  color: var(--grey);
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
}
.card_box .box .h_flx .flx {
  margin: 0;
}
.card_box .box .flx {
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: space-between;
  margin: 20px 0;
}
.card_box .box .flx + div {
  display: flex;
  align-items: center;
}

.card_box .box .flx .green {
  font-weight: 400;
  font-size: 24px;
  line-height: 140%;
  color: #2cc34c;
  margin-bottom: 10px;
}
.card_box .box .flx .days {
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  color: var(--grey);
}
.card_box .box .f_box {
  margin-top: 30px;
}
.card_box .box .f_box > div {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  justify-content: space-between;
}
.card_box .box .f_box > div > div:nth-child(1) {
  color: var(--grey);
}
.card_box .box .icon {
  margin: 0 5px;
}
.card_box .box .f_box > div:last-child {
  margin-top: 20px;
}
.card_box .box .coins {
  display: flex;
  align-items: center;
  gap: -2px;
}
.card_box .box .coins img:not(:first-child) {
  margin-left: -5px;
}
.card_box .box .copy_btn {
  background: rgba(119, 121, 137, 0.08);
  width: 100%;
  display: block;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  text-align: center;
  border-radius: 12px;
  text-align: center;
  color: var(--clr-font);
  margin-top: 20px;
  padding: 12px 0;
}
body.light-theme .card_box .box .icon {
  filter: invert(1);
}


.dark-theme   .css-1v2lvtn-MuiPaginationItem-root{
 color: #fff;
}
.or_b{
  color: #fff !important;
  fill: #fff !important;
  background: rgb(254, 119, 1) !important;
  border-radius: 8px;
  font-weight: 500;
}