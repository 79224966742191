footer {
  background: var(--clr-bcg);
  font-weight: 400;
  font-size: 14px;
  line-height: 129%;
  color: #8b8b8e;
  padding-top: 100px;
  //   box-shadow: 0px 2px 4px -1px #e46e0575, 0px 4px 5px 0px #e46e0575,
  //     0px 1px 10px 0px #e46e0575;
}
img {
  max-width: 100%;
  height: auto;
}
footer .logo {
  width: 134px;
  margin-bottom: 20px;

  padding-top: 0;
  padding-left: 0;
}
footer .social {
  display: flex;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
  padding: 0;
  margin-bottom: 30px;
  list-style-type: none;
  max-width: 170px;
}
footer .social a {
  display: block;
}
footer .links h5 {
  font-weight: 700;
  font-size: 18px;
  line-height: 140%;
  color: var(--clr-font);
  margin: 0;
  margin-bottom: 20px;
}
footer .links ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
footer .links a {
  font-weight: 400;
  font-size: 14px;
  line-height: 129%;
  color: #8b8b8e !important;
  text-decoration: none;
  display: block;
  margin-bottom: 15px;
}
footer .link_flx {
  display: flex;
  gap: 30px;
  justify-content: space-between;
}
footer .flx_blw {
  border-top: 1px solid #292929;
  padding-top: 10px;
  padding-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
footer .flx_blw > div:nth-child(2) {
  // filter: invert(1);
  width: 200px;
}
footer label.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root,
footer label.MuiInputLabel-formControl {
  color: #fff !important;
}
footer div#demo-simple-select {
  border: 1px solid #292929 !important;
  background: #1b1b1b;
}
footer .MuiSvgIcon-fontSizeMedium {
  filter: invert(1);
}
footer .MuiOutlinedInput-notchedOutline {
  border: 1px solid #292929 !important;
}
// .MuiPopover-root .css-6hp17o-MuiList-root-MuiMenu-list{
//     background: #292929;
//     color: var(--clr-font);
// }
