 @import './index.css';
 @import './media.css';
 @import './theme.css';

 /* @import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap'); */

 body {
     background-color: var(--body-bg);
     font-family: "Roboto";
     /* font-weight: 100; */
     font-style: normal;
     height: 100vh;
 }

 /* .App {
     margin-top: 70px;
 } */

 .App .tradingview-widget-container {
     margin-top: -70px !important;
 }

 p.MuiTypography-root,
 th,
 td,
 label,
 input,
 button.MuiButtonBase-root,
 a {
     font-family: "Roboto" !important;
 }

 @font-face {
     font-family: 'Roboto';
     src: url(../Font/Roboto/Roboto-Regular.ttf);
     font-display: swap;
 }

 /*Display -1*/

 .display-2 {
     display: flex;
     align-items: center;
     justify-content: space-between;
     color: var(--clr-font);
 }

 .display-1 {
     display: flex;
     align-items: center;
     justify-content: flex-start;
     gap: 10px;
 }

 .display-3 {
     display: flex;
     align-items: center;
     justify-content: center;
     /* height: 10vh; */
 }

 .display-4 {
     display: flex;
     align-items: center;
     justify-content: flex-end;
     /* height: 10vh; */
 }

 .cursor {
     cursor: pointer;
 }

 /*text-align*/

 .text-center {
     text-align: center !important;
 }

 .text-end {
     text-align: end !important;
 }

 .text-left {
     text-align: left !important;
 }

 .text-right {
     text-align: right !important;
 }


 .justi-center {
     justify-content: center;
 }

 .justi-end {
     justify-content: flex-end;
 }


 /*color*/

 .red {
     color: red;
 }

 .green {
     color: #2EBD85;
 }
 .order.green {
    color: #059462;
}


 .green-text {
     color: #2EBD85;
 }

 .text-primary {
     color: #007bff !important;
 }

 .bg-red {
     background-color: #dc3545 !important;
     color: #fff !important;
 }

 .bg-green {
     background-color: #2EBD85 !important;
 }

 /*Webkit scroll*/

 ::-webkit-scrollbar {
     width: 3px;
     height: 0px;
 }

 .MuiTableContainer-root:hover::-webkit-scrollbar {
     height: 3px !important;
     width: 3px !important;
 }

 .MuiTableContainer-root::-webkit-scrollbar {
     width: 0px;
 }

 ::-webkit-scrollbar-thumb {
     background-color: #6a6d7861;
 }

 .margin-top {
     margin-top: 20px;
 }


 .margin-t-100px {
     margin-top: 100px;
 }

 .mt-10 {
     margin-top: 10px;
 }

 .mt-20 {
     margin-top: 20px;
 }


 .mt-30 {
     margin-top: 30px;
 }

 .mt-40 {
     margin-top: 20px;
 }


 .mt-50 {
     margin-top: 50px;
 }

 .mt-80 {
     margin-top: 80px;
 }

 .mb-10 {
    margin-bottom: 10px;
}



 .padding-10 {
     padding: 10px;
 }

 .padding-20 {
     padding: 20px;
 }