@import '../../Scss/main.scss';
@import '../../Css/theme.css';


.card-part {
    background: #176491;
    padding: 21px 27px;
    border-radius: 12px;
    margin-bottom: 20px;
    color: #fff;
    height: max-content;
}
.card-container{
    display: $flex;
    justify-content: $center;
    gap: $gap-50;
    height: 100%;
    align-items: $center;
}
.earning-img {
    display: $flex;
    align-items: $center;
    gap: $gap-10;
}
.earning-full .ticket-box{
    height: auto !important;
}
.ticket-box h3{
   color: var(--clr-font);
}
.history-table th{
    background: var(--clr-bcg);
    color: var(--clr-font);
    white-space: nowrap;
}
.history-table td.MuiTableCell-root{
    color: var(--clr-font); 
}
body.dark-theme .history-table th{
    border-bottom: none !important;
}
body.dark-theme .history-table td.MuiTableCell-root{
    border-bottom: 1px solid #2b2929;
}