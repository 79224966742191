// Font family


$font-family-1: 'Roboto', sans-serif;



// Font Size
$font-14: 14px;
$font-16: 16px;
$font-18: 18px;
$font-20: 20px;
$font-25: 25px;
$font-30: 30px;
$font-40: 40px;
$font-45: 45px;


//Font weight

$weight-400: 400;
$weight-500: 500;
$weight-600: 600;
$weight-700: 700;
$weight-800: 800;
$weight-900: 900;

//Font case

$upper: uppercase;
$lower: lowercase;
$cap: capitalize;
