@import "../../Scss/main.scss";

.log-bcg {
  background-color: #fff;
}

.verify-form {
  display: $flex;
  align-items: $center;
  justify-content: $center;
  gap: $gap-10;
}

.verify-form input {
  width: $px-10;
  height: $px-10;
}
.logo img {
  width: 200px;
}
.logo {
  margin-bottom: $mar-25px;
  padding: $pad-20px;
}

.Login {
  background-image: url(../../Img/login-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
}
.email-field {
  margin-bottom: 10px;
}
.email-field label {
  margin-bottom: 15px;
}
.login-btn button {
  background: rgba(255, 112, 40, 1) !important;
  border: 1px solid rgba(255, 112, 40, 1);
  height: 45px;
  border-radius: 8px;
  text-transform: capitalize;
  font-size: 16px;
  color: #fff;
  //   transition: color 0.5s ease-in-out, box-shadow 0.5s ease-in-out;
}
// .login-btn button:hover {
//   box-shadow: inset 500px 0 0 0 rgba(255, 112, 40, 1);
// }

.accnt-sign a {
  color: rgba(152, 162, 179, 1);
}
.accnt-sign a span {
  color: rgba(255, 112, 40, 1);
}

.lgleft img {
  width: 100%;
  height: 100vh;
}

.logo.reg img {
  width: 150px;
  height: 60px;
}
.logo.reg {
  padding: 20px 0;
  margin-bottom: 20px;
}

.c-accout {
  //styleName: heading;
  // font-family: Poppins;
  font-size: 28px;
  font-weight: 600;
  line-height: 28px;
  text-align: left;
  color: #281a10;
}

h1.c-accout {
  //styleName: heading;
  // font-family: Poppins;
  font-size: 28px;
  font-weight: 600;
  line-height: 28px;
  text-align: left;
  color: #281a10;
}

.email-name {
  //styleName: body/large/regular;
  // font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: #344054;
}

.email-fill .MuiFormControl-root {
  border: 3px solid #ffecd1;
  border-radius: 8px;
}

.email-fill input {
  padding: 12px;
}

.email-fill .MuiFormControl-root:focus-visible {
  outline: none !important;
}

.email-fill fieldset {
  border: none;
}

.regbtn button {
  background: rgba(255, 112, 40, 1) !important;
  border: 1px solid rgba(255, 112, 40, 1) !important;
  border-radius: 8px;
  height: 45px;
  color: #fff;
  font-size: 16px;
  text-transform: capitalize;
  font-weight: 500;
}

.conti button {
  background: #ffefd1 !important;
  //   font-family: Roboto;
  font-size: 16px;
  font-weight: 600;
  //   line-height: 16px;
  text-align: left;
  color: #ff7028;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  height: 45px;
  border-radius: 8px;
  text-transform: capitalize;
}

.alraedy a {
  // font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: var(--foreground-low, #98a2b3);
}

.alraedy {
  // font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  text-align: center;
  color: var(--foreground-low, #5b616b);
}

.alraedy a span {
  //   font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: #ff7028;
}

.register {
  background: #fff !important;
  height: 100vh;
}
