@import '../../Scss/main.scss';
@import '../../Css/theme.css';

.referral-info {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
}
.field-box input {
    color: var(--clr-font) !important;
    -webkit-text-fill-color: var(--clr-font) !important;
}
.details th{
    background: var(--clr-bcg);
    color: var(--clr-font);
    white-space: nowrap;
}
.ticket-box h4{
    color:var(--clr-font);
}
body.dark-theme .details th {
    border-bottom: none !important;
}
body.dark-theme .details td.MuiTableCell-root {
    border-bottom: 1px solid #2b2929;
}
 .MuiPopover-paper.MuiMenu-paper{
    background: var(--clr-bcg);
    color:var(--clr-font);
}