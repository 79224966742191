// display property

$flex: flex;
$d-none:none;


// aligh items

$align-c: center;
$align-l: flex-start;
$align-r: flex-end;


//justify

$justi-c: center;
$justi-l: flex-start;
$justi-r: flex-end;
$justi-sb: space-between;
$justi-sa: space-around;
$justi-se: space-evenly;


//gap

$gap-10: 10px;
$gap-15: 15px;
$gap-20: 20px;
$gap-25: 25px;
$gap-30: 30px;
$gap-50: 50px;
$gap-70: 70px;


//flex-direction

$flex-column: column;
$flex-row: row;

//flex-wrap

$wrap: wrap;
$no-wrap: nowrap;


//border 

$border-1px: 1px;

// border-radius

$radius-6px: 6px;
$radius-8px: 8px;
$radius-10px: 10px;
$radius-12px: 12px;
$radius-15px: 15px;
$radius-20px: 20px;
$radius-25px: 25px;

//Text transform

$lower: lowercase;
$upper: uppercase;
$capital: capitalize;


//padding

$pad-10px: 10px;
$pad-15px: 15px;
$pad-20px: 20px;
$pad-25px: 25px;
$pad-30px: 30px;
$pad-50px: 50px;

//margin

$mar-10px: 10px;
$mar-15px: 15px;
$mar-20px: 20px;
$mar-25px: 25px;
$mar-30px: 30px;
$mar-40px: 40px;
$mar-50px: 50px;


// common pixels

$px-10: 10px;
$px-20: 20px;
$px-30: 30px;
$px-40: 40px;
$px-50: 50px;
$px-60: 60px;
$px-70: 70px;
$px-80: 80px;
$px-90: 90px;

$px-100: 100px;

$px-200: 200px;

$px-300: 300px;

$px-400: 400px;

$px-500: 500px;

// percentage

$percent-10: 10%;
$percent-20: 20%;
$percent-30: 30%;
$percent-40: 40%;
$percent-50: 50%;
$percent-60: 60%;
$percent-70: 70%;
$percent-80: 80%;
$percent-90: 90%;
$percent-100: 100%;

// text-align

$center: center;
$left: left;
$right: right;



// Font Size
$font-14: 14px;
$font-15: 15px;
$font-16: 16px;
$font-18: 18px;
$font-20: 20px;
$font-25: 25px;
$font-30: 30px;
$font-40: 40px;
$font-45: 45px;


//Font weight

$weight-400: 400;
$weight-500: 500;
$weight-600: 600;
$weight-700: 700;
$weight-800: 800;
$weight-900: 900;


// btn color
$btn-primary:  rgba(254, 119, 1, 1);